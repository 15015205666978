<template>
  <div v-if="preparations.length > 0">
    <div v-for="(medicalReceipt, index) in preparations" :key="index" class="w-full mb-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
      <div class="text-right">
        <button type="button" @click="removeMedicalReceipt(index)" class="text-right ms-auto -mx-1.5 -my-1.5 bg-white text-danger-600 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center
        justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" aria-label="Close">
          <span class="sr-only">Close</span>
          <font-awesome-icon :icon="['fas', 'trash']" />
        </button>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-5 gap-4 p-2 mb-2">
        <!-- Quantity Input -->
        <div class="md:col-span-1">
          <label for="quantity_label" class="block text-sm mb-2 font-medium text-gray-900 dark:text-white">Menge *</label>
          <input v-model="medicalReceipt.quantity" type="number" id="quantity_label" aria-describedby="helper-text-explanation" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Menge" required />
        </div>
        <!-- Preparation Multiselect -->
        <div class="md:col-span-3">
          <label :for="'preparation_label_' + index" class="block text-sm mb-2 font-medium text-gray-900 dark:text-white">Präparat *</label>
          <Multiselect v-model="medicalReceipt.medical" :options="medicals" :custom-label="nameMedical" placeholder="Select one" label="name"
                       track-by="id" :id="'preparation_label_' + index" class="w-full"
                       @update:modelValue="handleChange(medicalReceipt, index)"></Multiselect>
        </div>
        <!-- Size Input -->
        <div class="md:col-span-1">
          <label for="size_label" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Größe *</label>
          <select id="size_label" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

            <option value="">Wählen ...</option>
            <option value="N1">N1</option>
            <option value="N2">N2</option>
            <option value="N3">N3</option>

          </select>
        </div>
      </div>
      <!-- Recipe Multiselect -->
      <div class="grid grid-cols-1 md:grid-cols-1 gap-4 p-2 mb-2">
        <label for="recipe_label" class="block text-sm font-medium text-gray-900 dark:text-white">Rezeptur</label>
        <Multiselect v-model="medicalReceipt.formulation" :options="medicalReceipt.medical?.formulations || []" :custom-label="nameFormulation"
                     placeholder="Wählen..." id="recipe_label" class="w-full" track-by="id"/>
      </div>

      <!-- Message Textarea -->
      <div class="gap-4 p-2 mb-2">
        <label for="message_label" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nachricht</label>
        <textarea v-model="medicalReceipt.notice" id="message_label" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your thoughts here..."></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  props: {
    preparations: {
      type: Array, // Use Array, not []
      default: () => [],
    },
    medicals: {
      type: Array, // Use Array, not []
      default: () => [],
    },
  },
  data() {
    return {
      value: null, // For Multiselect
      optionsSize: ['N1', 'N2', 'N3']
    };
  },
  created() {
  },
  methods:{
    removeMedicalReceipt(index){
      this.$emit('removeMedicalReceipt', index);
    },
    nameFormulation (formulation) {
      if (Object.keys(formulation).length === 0) {
        return '';
      }
      return `${formulation?.name}`
    },
    nameMedical (medical) {
      if (Object.keys(medical).length === 0) {
        return '';
      }
      return `${medical.name}`
    },
    handleChange(medicalReceipt, index){

      this.$emit('updateSelectedFormulation', index);
    },

  },
  watch: {

  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
