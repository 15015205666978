<template>
  <div class="w-full mb-1">
    <header class="mb-4">
      <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
        {{ $t('SETTINGS') }}
      </h1>
    </header>
    <div class="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3 mb-4">
      <div class="p-4 border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6">
        <form @submit.prevent="submitHospitalSettings">

          <!-- Hospital Name -->
          <div class="mb-5">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{ $t('HOSPITAL_CONFIG.NAME') }}
            </label>
            <input
                type="text"
                v-model="hospital.name"
                id="name"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Name"
                required
            />
          </div>

          <!-- Hospital Domain -->
          <div class="mb-5">
            <label for="domain" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{ $t('HOSPITAL_CONFIG.DOMAIN') }}
            </label>
            <input
                type="text"
                v-model="hospital.domain"
                id="domain"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Website Url"
            />
          </div>

          <!-- Category Selection -->
          <div class="mb-5">
            <label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{ $t('HOSPITAL_CONFIG.CATEGORY') }}
            </label>
            <select
                v-model="selectedCategory"
                id="category"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="" disabled>{{ $t('SELECT_CATEGORY') }}</option>
              <option v-for="category in availableCategories" :key="category" :value="category">
                {{ category }}
              </option>
            </select>
          </div>

          <!-- Dynamic Settings -->
          <div v-for="(setting, index) in sortedSettings" :key="index" class="m-5">
            <div v-if="setting.input_type === 'text'">
              <label :for="'setting_' + setting.key" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{ setting.key }}
              </label>
              <input
                  type="text"
                  v-model="setting.value"
                  :id="'setting_' + setting.key"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  :placeholder="setting.key"
              />
            </div>

            <div v-if="setting.input_type === 'number'">
              <label :for="'setting_' + setting.key" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{ setting.key }}
              </label>
              <input
                  type="number"
                  v-model="setting.value"
                  :id="'setting_' + setting.key"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  :placeholder="setting.key"
              />
            </div>

            <div v-if="setting.input_type === 'select'">
              <label :for="'setting_' + setting.key" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{ setting.key }}
              </label>
              <select
                  v-model="setting.value"
                  :id="'setting_' + setting.key"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="active">{{ $t('HOSPITAL_CONFIG.ACTIVE') }}</option>
                <option value="inactive">{{ $t('HOSPITAL_CONFIG.INACTIVE') }}</option>
              </select>
            </div>
          </div>

          <!-- Actions -->
          <div class="flex justify-end gap-4">
            <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded-md">
              {{ $t('SAVE') }}
            </button>
            <button type="button" @click="cancelEdit" class="bg-gray-500 text-white px-4 py-2 rounded-md">
              {{ $t('CANCEL') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/config/axios-config";
import {eventBus} from "@/eventBus/eventBus";

export default {
  name: "HospitalSettingsForm",
  data() {
    return {
      hospital: {
        id: 0,
        name: "",
        domain: "",
        settings: [
          { key: 'hospital_name', value: '', input_type: 'text', category: 'general',name:'Title' },
          { key: 'hospital_subtitel', value: '', input_type: 'text', category: 'general',name:'Subtitle' },
          { key: 'hospital_capacity', value: '', input_type: 'number', category: 'general',name:'Capacity'},
          { key: 'feature_toggle', value: 'active', input_type: 'select', category: 'feature_toggle',name:'Toggle'}
        ],
      },
      availableCategories: ["general", "payment", "feature_toggle", "notification", "ui"],
      selectedCategory: "",
    };
  },
  computed: {
    sortedSettings() {
      return this.hospital.settings
          .filter(setting => !this.selectedCategory || setting.category === this.selectedCategory)
          .sort((a, b) => a.category.localeCompare(b.category));
    },
  },
  methods: {
    async fetchHospitalData() {
      try {
        const response = await axiosInstance.get(`/doctors/settings`);
        if(response.data && response.data.data){
          this.hospital = response.data.data;
        }
      } catch (error) {
        console.error("Error fetching hospital data:", error);
      }
    },
    async submitHospitalSettings() {
      const { id } = this.hospital.id;
      try {
        const method = id ? "put" : "post";
        const url = id ? `/doctors/settings/${id}` : "/doctors/settings";
        const response = await axiosInstance[method](url, this.hospital);

        eventBus.showToast(response.data.message, "success");
      } catch (error) {
        if(error && error.response.data){
          eventBus.showToast(error.response.data.message, "error");
        }
      }
    },
    cancelEdit() {
      this.$router.push({ name: "SETTING_LIST" });
    },
  },
  mounted() {

    this.fetchHospitalData();

  },
};
</script>


<style scoped>
/* Custom table styles, if needed */
</style>