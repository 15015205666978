export function formatDateTimestamp(isoString, formatForm = 'Y-m-d') {
    const date = new Date(isoString);

    // Get the day, month, year, hour, and minute
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // months are zero-based
    const year = date.getFullYear();
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');

    // Replace format placeholders with the actual values
    const formattedDate = formatForm
        .replace('Y', year)
        .replace('m', month)
        .replace('d', day)
        .replace('H', hour)
        .replace('i', minute);

    return formattedDate;
}
/*
    @paramester date 2024-10-29T10:15:02.000000Z
 */
export function formatTimeHI(isoString) {
    const date = new Date(isoString);
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');

    // Return the formatted date and time
    return `${hour}:${minute}`;
}
/*
    @paramester date 2024-10-29T10:15:02.000000Z
 */
export function formatDateDMY(isoString) {

    const date = new Date(isoString);

    // Get the day, month, year, hour, and minute
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // months are zero-based
    const year = date.getFullYear();


    // Return the formatted date and time
    return `${day}.${month}.${year}`;
}
export function formatDayOfWeek(isoString) {
    const date = new Date(isoString);
    let currentLocale = localStorage.getItem('doctorLanguage') || 'en-US';
    if(currentLocale === 'en' || currentLocale === 'en-US'){
        currentLocale = 'en-US';
    }
    if(currentLocale === 'de' || currentLocale === 'de-DE'){
        currentLocale = 'de-DE';
    }
    const options = { weekday: 'long' };
    const dayOfWeek = date.toLocaleDateString(currentLocale, options);
    return `${dayOfWeek}`;
}