<template>
  <div class="mb-4 flex justify-between items-center">
    <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">{{$t('AVAILABILITY_CONFIG.NAME')}}</h1>
  </div>

  <div class="grid grid-cols-5 lg:grid-cols-5 md:grid-cols-3 shadow-md sm:rounded-lg gap-4 mb-6">
    <div class="grid gap-4 lg:col-span-3 md:col-span-1">
      <div>
        <VueDatePicker
            class="w-full"
            :minDate="new Date()"
            v-model="selectedDate"
            @date-update="handleDateChange"
            :disabled = this.disableDatepicker
            inline>
          <template #action-row="{  internalModelValue  }">
            <div class="action-row">
              <div class="max-w-[16rem] mx-auto grid-cols-2 grid gap-4 mb-2">
                <div>
                  <label for="start-time" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Start time:</label>
                  <div class="relative">
                    <div class="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                      <font-awesome-icon :icon="['fas', 'clock']" />
                    </div>
                    <input type="time" v-model="startTime" id="start-time" class="bg-gray-50 border leading-none border-gray-300 text-gray-900
          text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500
          dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" min="09:00" max="24:00" required />
                  </div>
                </div>
                <div>
                  <label for="end-time" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">End time:</label>
                  <div class="relative">
                    <div class="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                      <font-awesome-icon :icon="['fas', 'clock']" />
                    </div>
                    <input type="time" v-model="endTime" id="end-time" class="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm
                    rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500
                    dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" min="09:00" max="23:59" required />
                  </div>
                </div>
              </div>
              <div class="text-right">
                <button type="button" @click="submitDateSelection(internalModelValue)" class="text-white bg-gradient-to-r from-blue-500
                via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300
                dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm
                px-5 py-2.5 text-center me-2 mb-2 ">{{$t('AVAILABILITY_CONFIG.ADD')}}</button>
              </div>
            </div>
          </template>
        </VueDatePicker>
      </div>
    </div>

    <div class="grid gap-4 lg:col-span-2 md:col-span-1">
      <div class="max-w-[16rem] grid grid-cols-1 gap-4 mb-2">
        <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
          <li class="flex items-center" v-for="createdSchedule in createdSchedules" :key="createdSchedule.id">

            <font-awesome-icon v-if="createdSchedule.status === 'confirmed'" :icon="['fas', 'circle-check']" />
            <font-awesome-icon v-else :icon="['fas', 'spinner']" />
            {{createdSchedule.date}} {{createdSchedule.start_time}} - {{createdSchedule.end_time}}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!--Table Content-->
  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">

    <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <div class="flex justify-between mb-4">
        <!-- Items per page dropdown -->
        <div>
          <button id="dropdownRadioButton" data-dropdown-toggle="dropdownRadio" class="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" type="button">
            {{ selectedOption }}
          </button>

          <!-- Dropdown menu -->
          <div id="dropdownRadio" class="z-10 hidden w-48 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600" data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="top">
            <ul class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownRadioButton">
              <li v-for="option in options" :key="option.value">
                <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                  <input
                      :id="'filter-radio-' + option.value"
                      type="radio"
                      :value="option.value"
                      v-model="selectedOption"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label :for="'filter-radio-' + option.value" class="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
                    {{ option.label }}
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- Search and Items Per Page Controls -->
        <div class="order-last">
          <!-- Search box -->
          <div class="">
            <input
                v-model="searchQuery"
                @input="filterData"
                type="text"
                placeholder="Search..."
                class="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
        </div>
      </div>
      <!-- Data table -->
      <div class="overflow-x-auto shadow-md rounded-lg">
        <table class="min-w-full bg-white table-auto text-left">
          <thead class="bg-gray-50">
          <tr>
            <th
                scope="col"
                class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            >
              <div class="flex items-center">
                <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 border-gray-300 rounded focus:ring-blue-500
                dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                dark:border-gray-600"
                       v-model="allSelected"
                       @change="toggleSelectAll">
                <label for="checkbox-all-search" class="sr-only">checkbox</label>
              </div>
            </th>
            <th
                scope="col"
                class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                @click="sortData('date_start_time')"
            >
              {{$t('AVAILABILITY_CONFIG.DATUM')}}
              <span v-if="sortColumn === 'date_start_time'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
            </th>
            <th
                scope="col"
                class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                @click="sortData('day_of_week')"
            >
              {{$t('AVAILABILITY_CONFIG.WEEKDAY')}}
              <span v-if="sortColumn === 'day_of_week'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
            </th>

            <th
                scope="col"
                class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                @click="sortData('status')"
            >
              {{$t('AVAILABILITY_CONFIG.STATUS')}}
              <span v-if="sortColumn === 'status'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
            </th>
            <th
                scope="col"
                class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                @click="sortData('id')"
            >
              {{$t('ACTION')}}
              <span v-if="sortColumn === 'id'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(row, index) in paginatedData"
              :key="index"
              class="border-b"
          >
            <td class="px-6 py-4">
              <div class="flex items-center">
                <input
                    :id="'checkbox-table-search-' + index"
                    type="checkbox"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    v-model="selectedRows[index]"
                    @change="updateSelectAllState"
                />
                <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
              </div>
            </td>
            <td class="px-6 py-4">{{ row.date }}
              {{ row.start_time.slice(0,-3) }} - {{ row.end_time.slice(0,-3) }}</td>
            <td class="px-6 py-4">{{ row.day_of_week }}</td>
            <td class="px-6 py-4">{{ formatDateTimestamp(row.created_at) }}</td>
            <td class="px-6 py-4">
              <button @click="goToEdit(row.id)" type="button" class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg
                hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700
                dark:focus:ring-blue-800">
                <span>{{$t('EDIT')}}</span>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination controls -->
      <div class="flex justify-between items-center mt-4">

        <!-- Items per page dropdown -->
        <div>
          <select id="perPage" v-model="perPage" @change="updatePerPage" class="p-2 border border-gray-300 rounded-md">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <span class="p-2">
            {{ $t("SHOW_ITEM_PER_PAGE", { startItem, endItem, totalRows }) }}
        </span>
        </div>
        <div>
          <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
            <li>
              <button
                  class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  :disabled="currentPage === 1"
                  @click="previousPage"
              >
                {{$t('PREVIOUS')}}
              </button>
            </li>
            <li v-for="page in totalPages" :key="page">
              <button
                  class="flex items-center justify-center px-3 h-8 leading-tight"
                  :class="currentPage === page ? 'text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white' : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'"
                  @click="goToPage(page)"
              >
                {{ page }}
              </button>
            </li>
            <li>
              <button
                  class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  :disabled="currentPage === totalPages"
                  @click="nextPage"
              >
                {{$t('NEXT')}}
              </button>
            </li>
          </ul>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axiosInstance from "@/config/axios-config";
import { formatDateTimestamp,formatDatestamp } from '@/helpers/dateFormat';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref } from 'vue';
import {eventBus} from "@/eventBus/eventBus";
export default {
  components: {
    VueDatePicker
  },
  data() {
    return {
      selectedDate: new Date(),
      time: ref(),
      startTime:'00:00',
      endTime: '00:00',
      searchQuery: '',
      sortColumn: null,
      sortDirection: 'asc',
      currentPage: 1,
      perPage: 5,
      rows: [],
      totalRows: 0,
      allSelected: false, // Manage state for 'Check All' checkbox
      selectedRows: [], // Array to track selected rows
      selectedOption: 'Last 30 days',
      options: [
        { label: 'Last day', value: 'Last day' },
        { label: 'Last 7 days', value: 'Last 7 days' },
        { label: 'Last 30 days', value: 'Last 30 days' },
        { label: 'Last month', value: 'Last month' },
        { label: 'Last year', value: 'Last year' },
      ],
      createdSchedules:[],
      disableDatepicker:false,
      hours: Array.from({ length: 25 }, (_, i) => i),
    };
  },
  computed: {
    paginatedData() {
      return this.rows;
    },
    totalPages() {
      return Math.ceil(this.totalRows / this.perPage);
    },
    startItem() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endItem() {
      const end = this.currentPage * this.perPage;
      return end > this.totalRows ? this.totalRows : end;
    },
  },
  methods: {
    formatDateTimestamp,formatDatestamp,
    formatTime(time) {
      // Check if time is valid
      if (!time || typeof time !== 'string') return 'Invalid Time';
      // Split the time string into components
      const [hours, minutes] = time.split(':');
      // Validate the components
      if (!hours || !minutes || isNaN(hours) || isNaN(minutes)) return 'Invalid Time';
      // Return formatted time
      return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    },

    selectTimeSlot(availability) {
      this.updateTimeSlot(availability);
    },
    async fetchData() {
      const response = await axiosInstance.get('doctors/schedules', {
        params: {
          page: this.currentPage,
          perPage: this.perPage,
          search: this.searchQuery,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
        },
      });
      this.rows = response.data.data;
      this.totalRows = response.data.total;
    },
    sortData(column) {
      if (this.sortColumn === column) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortDirection = 'asc';
      }
      this.fetchData(); // Fetch sorted data
    },
    filterData() {
      this.currentPage = 1;
      this.fetchData(); // Fetch filtered data
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchData();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchData();
      }
    },
    updatePerPage() {
      this.currentPage = 1; // Reset to the first page when items per page changes
      this.fetchData(); // Fetch updated data
    },
    goToPage(page) {
      // Check if the page number is valid
      if (page < 1 || page > this.totalPages) {
        return; // Ignore if page is out of bounds
      }
      this.currentPage = page; // Set the current page to the selected page

      // You can also add logic here to fetch new data based on the current page
      // For example:
      this.fetchData();
    },
    toggleSelectAll() {
      this.selectedRows = this.paginatedData.map(() => this.allSelected); // Set all checkboxes to the value of 'allSelected'
    },
    updateSelectAllState() {
      // Update 'allSelected' state based on individual checkboxes
      this.allSelected = this.selectedRows.every((selected) => selected);
    },
    goToEdit(id) {
      this.$router.push({ name: 'SCHEDULE_EDIT', params: { id: id } });
    },
    submitDateSelection(date) {
      axiosInstance.post('doctors/schedules',{
        date: date,
        start_time: this.startTime,
        end_time: this.endTime,
      }).then(response => {
        if (response && response.data) {

          eventBus.showToast(response.data.message, "success");
          this.$router.push({ name: 'SCHEDULE_EDIT', params: { id: response.data.schedule.id } });
        }
      })
      .catch(error => {
        this.disableDatepicker = false
        if(error && error.response.data){
          eventBus.showToast(error.response.data.message, "error");
        }
      });
    },
    fetchCertainlyDateData(date){
      this.disableDatepicker = true
      axiosInstance.get('doctors/schedules',{
        params: {
          page: this.currentPage,
          perPage: 100000,
          search: this.searchQuery,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
          date: date,
        },
      }).then(response => {
        this.disableDatepicker = false
        if (response && response.data) {
          console.log('response',response.data)
          this.createdSchedules = response.data.data
        }
      })
          .catch(error => {
            this.disableDatepicker = false
            //this.showToast('Failed to load services');
            console.log(error)
          })
          .finally(() => {
            this.disableDatepicker = false
            //this.loading = false;
          });
    },
    handleDateChange(date){
      this.fetchCertainlyDateData(date);
    }

  },
  mounted() {
    this.fetchData(); // Fetch data when component mounts
    this.fetchCertainlyDateData(this.selectedDate);
  },
};
</script>

<style lang="scss">
.action-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>