<template>
  <div class="mb-4 flex justify-between items-center">
    <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
      {{$t('AVAILABILITY_CONFIG.CREATE')}}
    </h1>
  </div>

  <div class="grid grid-cols-5 lg:grid-cols-5 md:grid-cols-3 shadow-md sm:rounded-lg gap-4 mb-6">
    <div class="grid gap-4 lg:col-span-3 md:col-span-1">
      <div>
        <VueDatePicker
            class="w-full"
            :minDate="new Date()"
            v-model="schedule.date"
            @date-update="handleDateChange"
            :disabled = this.disableDatepicker
            inline>
          <template #action-row="{  internalModelValue  }">
            <div class="action-row">
              <div class="max-w-[16rem] mx-auto grid-cols-2 grid gap-4 mb-2">
                <div>
                  <label for="start-time" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Start time:</label>
                  <div class="relative">
                    <div class="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                      <font-awesome-icon :icon="['fas', 'clock']" />
                    </div>
                    <input type="time" v-model="schedule.start_time" id="start-time" class="bg-gray-50 border leading-none border-gray-300 text-gray-900
          text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500
          dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" min="09:00" max="24:00" required />
                  </div>
                </div>
                <div>
                  <label for="end-time" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">End time:</label>
                  <div class="relative">
                    <div class="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                      <font-awesome-icon :icon="['fas', 'clock']" />
                    </div>
                    <input type="time" v-model="schedule.end_time" id="end-time" class="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm
                    rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500
                    dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" min="09:00" max="23:59" required />
                  </div>
                </div>
              </div>
              <div class="text-right">
                <button type="button" @click="submitSchedule(internalModelValue)" class="text-white bg-gradient-to-r from-blue-500
                via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300
                dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm
                px-5 py-2.5 text-center me-2 mb-2 ">{{$t('AVAILABILITY_CONFIG.ADD')}}</button>
              </div>
            </div>
          </template>
        </VueDatePicker>
      </div>
    </div>

    <div class="grid gap-4 lg:col-span-2 md:col-span-1">
      <div class="max-w-[16rem] grid grid-cols-1 gap-4 mb-2">
        <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
          <li class="flex items-center" v-for="createdSchedule in createdSchedules" :key="createdSchedule.id">

            <font-awesome-icon v-if="createdSchedule.status === 'confirmed'" :icon="['fas', 'circle-check']" />
            <font-awesome-icon v-else :icon="['fas', 'spinner']" />
            {{createdSchedule.date}} {{createdSchedule.start_time}} - {{createdSchedule.end_time}}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!--Table Content-->
</template>

<script>
import axiosInstance from "@/config/axios-config";
import { formatDateTimestamp } from '@/helpers/dateFormat';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref } from 'vue';
import {eventBus} from "@/eventBus/eventBus";
export default {
  components: {
    VueDatePicker
  },
  data() {
    return {
      schedule:{
        id:0,
        date: new Date(),
        date_end_time:'',
        date_start_time:'',
        day_of_week:'',
        doctor_id:'',
        duration:0,
        is_locked:0,
        locked_until:0,
        start_time:'00:00',
        end_time:'00:00',
      },
      selectedDate: new Date(),
      time: ref(),
      currentPage: 1,
      perPage: 5,
      rows: [],
      totalRows: 0,
      allSelected: false,
      selectedRows: [],
      selectedOption: 'Last 30 days',
      options: [
        { label: 'Last day', value: 'Last day' },
        { label: 'Last 7 days', value: 'Last 7 days' },
        { label: 'Last 30 days', value: 'Last 30 days' },
        { label: 'Last month', value: 'Last month' },
        { label: 'Last year', value: 'Last year' },
      ],
      createdSchedules:[],
      disableDatepicker:false,
      hours: Array.from({ length: 25 }, (_, i) => i),
    };
  },
  computed: {
    startItem() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endItem() {
      const end = this.currentPage * this.perPage;
      return end > this.totalRows ? this.totalRows : end;
    },
  },
  methods: {
    formatDateTimestamp,
    async fetchScheduleData() {
      // Fetch existing patient data to pre-fill the form
      const response = await axiosInstance.get(`doctors/schedules/${this.$route.params.id}`);
      if (response.data) {
        this.schedule = response.data.data;
        this.fetchCertainlyDateData(this.schedule.date);
      } else {
        console.error('Error fetching patient data:', response.statusText);
      }
    },
    async fetchData() {
      const response = await axiosInstance.get('doctors/schedules', {
        params: {
          page: this.currentPage,
          perPage: this.perPage,
          search: this.searchQuery,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
        },
      });
      this.rows = response.data.data;
      this.totalRows = response.data.total;
    },

    fetchCertainlyDateData(date){
      this.disableDatepicker = true
      axiosInstance.get('doctors/schedules',{
        params: {
          page: this.currentPage,
          perPage: 100000,
          search: this.searchQuery,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
          date: date,
        },
      }).then(response => {
        this.disableDatepicker = false
        if (response && response.data) {
          console.log('response',response.data)
          this.createdSchedules = response.data.data
        }
      })
          .catch(error => {
            this.disableDatepicker = false
            //this.showToast('Failed to load services');
            console.log(error)
          })
          .finally(() => {
            this.disableDatepicker = false
            //this.loading = false;
          });
    },
    handleDateChange(date){
      this.fetchCertainlyDateData(date);
    },
    async submitSchedule() {
      const isUpdate = !!this.$route.params.id;
      const endpoint = this.$route.params.id
          ? `doctors/schedules/${this.schedule.id}`
          : 'doctors/schedules';
      const method = this.$route.params.id ? 'put' : 'post';
      try {
        const response = await axiosInstance[method](endpoint, { schedule: this.schedule });
        this.handleResponse(response,isUpdate);
      } catch (error) {
        this.handleError(error);
      }
    },
    handleResponse(response,isUpdate) {
      if (response && response.data) {
        eventBus.showToast(response.data.message, "success");
        if (!isUpdate) {
          this.$router.push({
            name: 'PATIENT_EDIT',
            params: { id: response.data.data.id }
          });
        }
      }
    },

    handleError(error) {
      if (error && error.response && error.response.data) {
        eventBus.showToast(error.response.data.message, "error");
      } else {
        console.error('An error occurred:', error);
      }
    },
  },
  mounted() {
    if(this.$route.params.id){
      this.fetchScheduleData();
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.fetchScheduleData(newId); // Call a method to fetch the patient data based on new ID
        }
      }
    }
  },
};
</script>

<style lang="scss">
.action-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>