<script>
export default {
  data () {
    return {
      headers: [
        {
          title: this.$t('PATIENT'),
          align: 'start',
          key: 'patient',
        },

        { title: this.$t('PRACTITIONER'), align: 'end', key: 'behandler' },
        { title: this.$t('STATUS'), align: 'end', key: 'status' },
      ],
      patients : [
        { id: 1, patient: 'John Doe', behandler: 'Behandler 1', status: 'Active' },
        { id: 2, patient: 'Jane Smith', behandler: 'Behandler 2', status: 'Inactive' },
        { id: 3, patient: 'Michael Johnson', behandler: 'Behandler 3', status: 'Active' },
        { id: 4, patient: 'Emily Davis', behandler: 'Behandler 4', status: 'Pending' },
        { id: 5, patient: 'Daniel Brown', behandler: 'Behandler 5', status: 'Active' },
        { id: 6, patient: 'Emma Wilson', behandler: 'Behandler 6', status: 'Inactive' },
        { id: 7, patient: 'Olivia Martinez', behandler: 'Behandler 7', status: 'Active' },
        { id: 8, patient: 'James Anderson', behandler: 'Behandler 8', status: 'Active' },
        { id: 9, patient: 'Isabella Garcia', behandler: 'Behandler 9', status: 'Pending' },
        { id: 10, patient: 'Lucas Rodriguez', behandler: 'Behandler 10', status: 'Inactive' },
        { id: 11, patient: 'Mia Hernandez', behandler: 'Behandler 11', status: 'Active' },
        { id: 12, patient: 'Liam Lee', behandler: 'Behandler 12', status: 'Active' },
        { id: 13, patient: 'Sophia Walker', behandler: 'Behandler 13', status: 'Inactive' },
        { id: 14, patient: 'Noah Hall', behandler: 'Behandler 14', status: 'Pending' },
        { id: 15, patient: 'Ava Young', behandler: 'Behandler 15', status: 'Active' },
      ]
    }
  },
}
</script>

<template>
  <div class="mb-4 flex justify-between items-center">
    <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
      {{$t('DASHBOARD')}}
    </h1>

  </div>
  <div class="grid grid-cols-2 md:grid-cols-3 gap-4 mb-6">
      <div class="p-6  border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <p class="mb-3 text-white font-weight-bold">{{$t('ALL')}}</p>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">496</p>
      </div>
      <div class="p-6  border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <p class="mb-3 text-white font-weight-bold">{{$t('PROCESSED_VIA_MYCARE')}}</p>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">444</p>
      </div>
      <div class="p-6  border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <p class="mb-3 text-white font-weight-bold">{{$t('PROCESSED_VIA_WELTAPOTHEKE')}}</p>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">10</p>
      </div>
      <div class="p-6  border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <p class="mb-3 text-white font-weight-bold">{{$t('PROCESSED_VIA_RONDELL_APOTHEKE')}}</p>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">7</p>
      </div>
      <div class="p-6  border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <p class="mb-3 text-white font-weight-bold">{{$t('POSTAL_DISPATCH')}}</p>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">13</p>
      </div>

    </div>

  <div class="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3">
    <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <v-data-table
          :headers="headers"
          :items="patients"
          item-value="name"
          select-strategy="single"
          show-select
      ></v-data-table>
    </div>
  </div>

</template>
<style scoped>

</style>