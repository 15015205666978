<template>
  <div
      id="toast-top-right"
      v-if="eventBus.toast.show"
      :class="['fixed flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 bg-white divide-x rtl:divide-x-reverse' +
       ' divide-gray-200 rounded-lg shadow top-20 right-5 dark:text-gray-400 dark:divide-gray-700 dark:bg-gray-800', toastTypeClasses]"
      role="alert"
  >
    <div :class="['inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg', iconBgClasses]">
      <font-awesome-icon v-if="eventBus.toast.type === 'success'" class="text-green-500 dark:text-green-200" :icon="['far', 'circle-xmark']" />
      <font-awesome-icon v-if="eventBus.toast.type === 'error'" class="text-red-500 dark:text-red-200" :icon="['far', 'circle-xmark']" />
      <font-awesome-icon v-if="eventBus.toast.type === 'warning'" class="text-orange-500 dark:text-orange-200" :icon="['far', 'circle-xmark']" />
      <span class="sr-only">{{ eventBus.toast.type }} icon</span>
    </div>
    <div class="ms-3 text-sm font-normal">{{ eventBus.toast.message }}</div>
    <button
        type="button"
        @click="closeToast"
        class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
        aria-label="Close"
    >
      <font-awesome-icon :icon="['fas', 'xmark']" />
      <span class="sr-only">Close</span>
    </button>
  </div>
</template>

<script>
import {eventBus} from "@/eventBus/eventBus";

export default {

  name: "ToastComponent",
  setup() {
    return { eventBus };
  },

  computed: {

      toastTypeClasses() {
        return {
          "bg-green-100 dark:bg-green-800": this.eventBus.toast.type === "success",
          "bg-red-100 dark:bg-red-800": this.eventBus.toast.type === "error",
          "bg-orange-100 dark:bg-orange-700": this.eventBus.toast.type === "warning",
        };
      },
      iconBgClasses() {
        return {
          "text-green-500 dark:text-green-200": this.eventBus.toast.type === "success",
          "text-red-500 dark:text-red-200": this.eventBus.toast.type === "error",
          "text-orange-500 dark:text-orange-200": this.eventBus.toast.type === "warning",
        };
      },
  },
  methods: {
    closeToast() {
      this.eventBus.hideToast();
    },
  }
};
</script>

<style scoped>
/* Add any additional styling here if necessary */
</style>
