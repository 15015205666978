<style src="./LoginComponent.scss" lang="scss" scoped></style>
<script src="./LoginComponent.js"></script>

<template>
  <div class="flex flex-col items-center justify-center px-6 mx-auto md:h-screen pt:mt-0 dark:bg-gray-900">
    <a href="" class="flex items-center justify-center mb-8 text-2xl font-semibold lg:mb-10 dark:text-white">
      <span>Doctor-Online-Klinik</span>
    </a>
    <!-- Card -->
    <div class="w-full max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow dark:bg-gray-800">
      <form class="mt-8 space-y-6" method="POST">
        <div>
          <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{$t('LOGIN_CONFIG.EMAIL')}}</label>
          <input v-model="email" type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="name@company.com" required>
          <p v-if="errors.email" class="mt-2 text-sm text-red-600">{{ errors.email[0] }}</p>
        </div>
        <div>
          <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{$t('LOGIN_CONFIG.PASSWORD')}}</label>
          <input v-model="password" type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required>
          <p v-if="errors.password" class="mt-2 text-sm text-red-600">{{ errors.password[0] }}</p>
        </div>
        <div class="flex items-start">
          <div class="flex items-center h-5">
            <input id="remember" aria-describedby="remember" name="remember" type="checkbox" class="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" required>
          </div>
          <div class="ml-3 text-sm">
            <label for="remember" class="font-medium text-gray-900 dark:text-white">Remember me</label>
          </div>
          <a href="#" class="ml-auto text-sm text-primary-700 hover:underline dark:text-primary-500">{{$t('LOGIN_CONFIG.FORGOT_PASSWORD')}}</a>
        </div>
        <button type="button" @click="handleLogin" class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg
        border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700
        dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">{{$t('LOGIN_CONFIG.LOGIN')}}</button>
        <div class="text-sm font-medium text-gray-500 dark:text-gray-400">
          {{$t('LOGIN_CONFIG.NOT_REGISTER')}} <a class="text-primary-700 hover:underline dark:text-primary-500">{{$t('LOGIN_CONFIG.CREATE_ACCOUNT')}}</a>
        </div>
      </form>
    </div>
  </div>
</template>