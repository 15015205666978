import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';

// Create the router instance and pass the `history` option correctly
const router = createRouter({
    history: createWebHistory(),  // Use the `history` option instead of `mode`
    linkActiveClass: 'active',
    scrollBehavior() {
        return { x: 0, y: 0 };  // Scroll to the top on route change
    },
    routes,  // Short for `routes: routes`
});

// Navigation Guard to protect routes
router.beforeEach((to, from, next) => {
    // Check if the route requires authentication
    document.title = `${to.meta.title} - ${process.env.VUE_APP_NAME}`;
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // Check if user is authenticated (replace with your actual auth check)
        const isAuthenticated = localStorage.getItem('auth'); // Example check
        if (!isAuthenticated) {
            // If not authenticated, redirect to login page
            next({ name: 'Login' });
        } else {
            // If authenticated, proceed to the route
            next();
        }
    } else {
        // If the route doesn't require authentication, proceed as normal
        next();
    }
});


export default router;
