<template>
  <div class="w-full mb-1">
    <div class="mb-4">
      <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
        {{$t('PATIENT_CONFIG.EDIT')}}
      </h1>
    </div>
    <div class="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3 mb-4">
      <div class="p-4 border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6">
        <form @submit.prevent="submitPatient" class="max-w-md mx-auto">
          <div class="grid md:grid-cols-2 md:gap-6">
            <div class="relative z-0 w-full mb-5 group">
              <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{$t('PATIENT_CONFIG.FIRST_NAME')}}
              </label>
              <input type="text" v-model="patient.first_name" id="first_name"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     placeholder=" " required />
            </div>
            <div class="relative z-0 w-full mb-5 group">
              <label for="last_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{$t('PATIENT_CONFIG.LAST_NAME')}}
              </label>
              <input type="text" v-model="patient.last_name" id="last_name"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     placeholder=" " required />
            </div>
          </div>
          <div class="relative z-0 w-full mb-5 group">
            <label for="birthday" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{$t('PATIENT_CONFIG.BIRTHDAY')}}
            </label>
            <input type="date" v-model="patient.birthdate" id="birthday"
                   class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                   required />
          </div>
          <div class="grid md:grid-cols-2 md:gap-6">
            <div class="relative z-0 w-full mb-5 group">
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{$t('PATIENT_CONFIG.E_MAIL')}}
              </label>
              <input type="email" v-model="patient.email" id="email"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     placeholder=" " required />
            </div>

            <div class="relative z-0 w-full mb-5 group">
              <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{$t('PATIENT_CONFIG.MOBIL')}}
              </label>
              <input type="tel" v-model="patient.phone" pattern="0[0-9]{10}"  id="phone"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     placeholder="123-456-7890" required />
            </div>
          </div>

          <div class="relative z-0 w-full mb-5 group">
            <label for="address" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{$t('PATIENT_CONFIG.ADDRESS')}}
            </label>
            <input type="text" v-model="patient.address" id="address"
                   class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                   placeholder=" " required />
          </div>

          <div class="relative z-0 w-full mb-5 group text-right">
            <button type="submit" class="mr-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              {{$t('UPDATE')}}
            </button>
            <button type="button" @click="cancelEdit" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
              {{$t('CANCEL')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/config/axios-config";
import {eventBus} from "@/eventBus/eventBus";
export default {
  name: 'PatientEditComponent',

  data() {
    return {
      patient: {
        id: 0,
        first_name: '',
        last_name: '',
        birthday: '',
        email: '',
        phone: '',
        address: '',
      },
    };
  },

  methods: {
    async submitPatient() {
      const isUpdate = !!this.$route.params.id;
      const endpoint = this.$route.params.id
          ? `doctors/patients/${this.patient.id}`
          : 'doctors/patients';
      const method = this.$route.params.id ? 'put' : 'post';
      try {
        const response = await axiosInstance[method](endpoint, { patient: this.patient });
        this.handleResponse(response,isUpdate);
      } catch (error) {
        this.handleError(error);
      }
    },

    handleResponse(response,isUpdate) {
      if (response && response.data) {
        eventBus.showToast(response.data.message, "success");
        if (!isUpdate) {
          this.$router.push({
            name: 'PATIENT_EDIT',
            params: { id: response.data.data.id }
          });
        }
      }
    },

    handleError(error) {
      if (error && error.response && error.response.data) {
        eventBus.showToast(error.response.data.message, "error");
      } else {
        console.error('An error occurred:', error);
      }
    },

    async fetchPatientData() {
      // Fetch existing patient data to pre-fill the form
      const response = await axiosInstance.get(`doctors/patients/${this.$route.params.id}`);
      if (response.data) {
        this.patient = response.data.data;
      } else {
        console.error('Error fetching patient data:', response.statusText);
      }
    },
    cancelEdit() {
      this.$router.push({ name: 'Patients' });
    },
  },

  // You can fetch the patient data when the component mounts
  mounted() {
    if(this.$route.params.id){
      this.fetchPatientData();
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.fetchPatientData(newId); // Call a method to fetch the patient data based on new ID
        }
      }
    }
  },


};
</script>

<style scoped>
/* Add your styles here, if needed */
</style>
