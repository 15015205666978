<template>
  <footer class=" mt-4 shadow  md:p-6 xl:p-8 dark:bg-gray-800">
    <p class=" text-sm text-center text-gray-500">
      &copy;  <a href="" class="hover:underline" target="_blank">Copyright schubwerk GmbH 2024</a>
    </p>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  // Optional props or data
  data() {
    return {
      currentYear: new Date().getFullYear(), // Get the current year using JavaScript
    };
  }
};
</script>

<style scoped>
/* Scoped styles specific to the header */
footer {
  position: relative;
  width: 100%;
  background-color: #1F2937; /* Dark background */
  color: white;
  text-align: center;
  font-size: 0.875rem; /* Small font size */
  margin-top: auto; /* This will push the footer to the bottom in a flex layout */
}
</style>
