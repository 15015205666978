<template>
  <li style="list-style: none">
    <select v-model="setDoctorLanguage" id="lang" name="language-select" @change="changeLocale"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
      <option v-for="lang in languages" :key="lang.code" :value="lang.code">
        {{ $t(lang.label) }}  <!-- Translation using the i18n plugin -->
      </option>
    </select>
  </li>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
  data() {
    return {
      // Initialize with the language from Vuex
      setDoctorLanguage: 'en', // Set to empty initially
      languages: [
        {code: 'de', label: 'TOP_NAVIGATION_MENU.GERMAN_LANGUAGE_SELECTOR'},
        {code: 'en', label: 'TOP_NAVIGATION_MENU.ENGLISH_LANGUAGE_SELECTOR'},
        // Add more languages if needed
      ],
    };
  },
  computed: {
    ...mapState({
      doctorLanguage: (state) => state.doctorLanguage, // Get current language from Vuex
    }),
  },
  methods: {
    ...mapActions(['changeDoctorLanguage']), // Map the Vuex action
    changeLocale() {
      this.changeDoctorLanguage(this.setDoctorLanguage); // Call the Vuex action with the selected language
      localStorage.setItem('doctorLanguage', this.setDoctorLanguage);

    },
  },
  watch: {
    doctorLanguage(newLang) {
      this.setDoctorLanguage = newLang; // Update the local selected language if Vuex state changes
    },
  },
  created() {
    // Set initial selected language from Vuex
    this.setDoctorLanguage = this.doctorLanguage; // Reflect the language state
  },
};
</script>

<style scoped>
/* Add any styles here if needed */
</style>
