import axios from 'axios';
import router from "@/router/router";

// Create an Axios instance with default configuration
const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL, // Replace with your Laravel app URL
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }
});

// Add a request interceptor to include the Bearer token in every request
axiosInstance.interceptors.request.use(
    (config) => {
        // Get the token from localStorage
        const token = localStorage.getItem('auth');
        if (token) {
            // Add the Authorization header if the token exists
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        // Handle request error
        return Promise.reject(error);
    }
);
// Add a response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Clear any stored token if unauthorized
            localStorage.removeItem('auth');
            localStorage.removeItem('doctorUser');
            // Redirect to login page
            router.push({ name: 'login' });
        }
        return Promise.reject(error);
    }
);

// Add a request interceptor to update the X-Locale header dynamically
axiosInstance.interceptors.request.use((config) => {
    config.headers['X-Locale'] = localStorage.getItem('doctorLanguage') || 'en';
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default axiosInstance;
