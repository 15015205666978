import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router';
import store from './store/store';

import 'flowbite';
import 'flowbite/dist/flowbite.css';
import 'flowbite/dist/flowbite.js';
import './components/_globals';
import './assets/styles/main.css';
import './assets/styles/datepicker.scss';
import './assets/styles/sidebar.scss';
import '../node_modules/flowbite-vue/dist/index.css'

/**************** START I18N ****************/
import i18n, { setI18nLocale } from './config/i18n';
/**************** END I18N ****************/

/**************** START vuetify ****************/

/**************** END vuetify ****************/
/**************** START Fontawesome ****************/
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// Import specific icons (add the icons you need)
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

// Add all icons to the library
library.add(fas, far, fab)

/**************** END Fontawesome ****************/
store.subscribe((mutation) => {
    if (mutation.type === 'setDoctorLanguage') {
        setI18nLocale(mutation.payload); // Update the i18n locale when Vuex state changes
    }
});

createApp(App)
    .use(i18n)
    .use(router)
    .use(store)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
