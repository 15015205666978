// src/eventBus.js
import { reactive } from 'vue';

export const eventBus = reactive({
    toast: {
        message: '',
        type: 'success',
        show: false,
    },
    showToast(message, type = 'success') {
        this.toast.message = message;
        this.toast.type = type;
        this.toast.show = true;

        // Hide toast automatically after 5 seconds
        setTimeout(() => {
            this.hideToast();
        }, 5000); // 5000 milliseconds = 5 seconds
    },
    hideToast() {
        this.toast.show = false;
    },
});
