
import { mapActions } from 'vuex';
import axiosInstance from "@/config/axios-config";
export default {
    name: "LoginComponent",
    data() {
        return {
            email: "",
            password: "",
            user_type: "",
            errors: {},
        };
    },
    methods: {
        ...mapActions(['login','setProfile']),
        async handleLogin() {
            try {
                // Make a POST request to your API login endpoint
                const response = await axiosInstance.post('/login', {
                    email: this.email,
                    password: this.password,
                });
                // Assuming the token is returned in response.data.token
                const token = response.data.access_token;
                const user = response.data.user;
                if(response.data.user){
                    this.errors = {};
                }
                // Save the token to localStorage (or VueX)
                this.login(token);
                // Save the user information to localStorage (or VueX)
                this.setProfile(user);

                // Redirect the user to a protected route (e.g., Dashboard)
                this.$router.push({ name: "SERVICE_LIST" });
            } catch (error) {
                this.errors =error.response.data.errors;
                // Handle login errors (e.g., invalid credentials)

            }
        },
    },
};
