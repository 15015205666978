<template>
  <div class="w-full mb-1">
    <div class="mb-4">
      <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
        {{$t('PRESCRIPTION_CONFIG.EDIT')}}
      </h1>
    </div>
    <div class="grid grid-cols-3 md:grid-cols-3 gap-4 rounded-lg shadow sm:p-6 md:p-8">
      <div class="col-span-2">

          <div class="mb-5">
            <label for="patient_select" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{$t('PRESCRIPTION_CONFIG.PATIENT')}}</label>
            <Multiselect v-model="prescription.patient" :options="doctors" :custom-label="firstNameLastNameDoctor"
                         placeholder="Select one" label="name"
                         track-by="id"></Multiselect>
          </div>
          <div class="mb-5">
            <label for="patient_select" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{$t('PRESCRIPTION_CONFIG.PRACTITIONER')}}</label>
            <Multiselect v-model="prescription.doctor" :options="patients" :custom-label="firstNameLastNamePatient" placeholder="Select one" label="name"
                         track-by="id"></Multiselect>
          </div>

        <div class="mb-5">
          <label for="status" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            {{$t('STATUS')}}
          </label>
          <select v-model="prescription.status" id="status" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
            dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <option value="active">{{$t('SERVICE_CONFIG.ACTIVE')}}</option>
            <option value="inactive">{{$t('SERVICE_CONFIG.INACTIVE')}}</option>
          </select>
        </div>
          <div class="mb-5">
            <h6 class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{$t('PRESCRIPTION_CONFIG.PREPARATIONS')}}
            </h6>
            <PreparationFormComponent v-model:preparations="this.prescription.medical_prescriptions"
                                      @removeMedicalReceipt="removeMedicalReceipt"
                                      @updateSelectedFormulation="updateSelectedFormulation"
                                      :medicals="medicals"></PreparationFormComponent>
          </div>
        <div class="text-center">
          <button type="button"
                  @click="addMedicalPrescription"
                  class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4
              focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg
              dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
            {{$t('FORMULATION_CONFIG.ADD')}}
          </button>
        </div>
      </div>
      <div>
        <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Solid foundation for any project</h3>
          <p class="my-4">Designing with Figma components that can be easily translated to the utility classes of Tailwind CSS is a huge timesaver!"</p>
        </blockquote>

      </div>
    </div>
    <div class="text-right mt-2">

        <button type="submit" @click="submitPrescription" class="mr-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          {{$t('UPDATE')}}
        </button>
        <button type="button" @click="cancelEdit" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
          {{$t('CANCEL')}}
        </button>

    </div>
  </div>
</template>

<script>
import axiosInstance from "@/config/axios-config";
import Multiselect from 'vue-multiselect'
import PreparationFormComponent from "@/components/preparations/PreparationFormComponent.vue";
import {eventBus} from "@/eventBus/eventBus";

export default {
  name: 'PrescriptionEditComponent',
  components: {PreparationFormComponent, Multiselect },

  data() {
    return {
      prescription: {
        id: 0,
        last_name: '',
        birthday: '',
        email: '',
        status: '',
        patient: {},
        doctor: {},
        medical_prescriptions: [],
      },
      patients:[],
      patient: {},
      doctors:[],
      doctor: {},
      medicals:[],
      value: null,
      optionsSize: ['n1', 'n2', 'n3']
    };
  },

  methods: {
    async submitPrescription() {
      const isUpdate = !!this.$route.params.id;
      const endpoint = this.$route.params.id
          ? `doctors/prescriptions/${this.prescription.id}`
          : 'doctors/prescriptions';
      const method = this.$route.params.id ? 'put' : 'post';
      try {
        const response = await axiosInstance[method](endpoint, { prescription:this.prescription, });
        this.handleResponse(response,isUpdate);
      } catch (error) {
        this.handleError(error);
      }
    },

    handleResponse(response,isUpdate) {
      if (response && response.data) {
        eventBus.showToast(response.data.message, "success");
        if (!isUpdate) {
          this.$router.push({
            name: 'PRESCRIPTION_EDIT',
            params: { id: response.data.data.id }
          });
        }
      }
    },

    handleError(error) {
      if (error && error.response && error.response.data) {
        eventBus.showToast(error.response.data.message, "error");
      } else {
        console.error('An error occurred:', error);
      }
    },

    firstNameLastNamePatient (patient) {
      if(patient.id !== undefined){
        return `${patient.first_name} ${patient.last_name}`
      }
      return '';

    },
    firstNameLastNameDoctor (doctor) {
      if(doctor.id !== undefined){
        return `${doctor.first_name} ${doctor.last_name}`
      }
      return '';
    },
    fetchPatientData() {
      axiosInstance.get('doctors/list-patients',{
        params: {
          page: 1,
          perPage: 100000,
        },
      }).then(response => {
        if (response && response.data) {
          console.log('response',response.data)
          this.patients = response.data.data
        }
      })
      .catch(error => {
        //this.showToast('Failed to load services');
        console.log(error)
      })
    },
    fetchDoctorData() {
      axiosInstance.get('doctors/users',{
        params: {
          page: 1,
          perPage: 100000,
        },
      }).then(response => {
        if (response && response.data) {
          this.doctors = response.data.data
        }
      })
      .catch(error => {
        //this.showToast('Failed to load services');
        console.log(error)
      })
    },
    fetchMedicalData() {
      axiosInstance.get('doctors/medicals',{
        params: {
          page: 1,
          perPage: 100000,
        },
      }).then(response => {
        if (response && response.data) {
          this.medicals = response.data.data
        }
      })
      .catch(error => {
        //this.showToast('Failed to load services');
        console.log(error)
      })
    },
    async fetchPrescriptionData() {
      const response = await axiosInstance.get(`doctors/prescriptions/${this.$route.params.id}`);
      if (response.data) {
        this.prescription = response.data.data;
      } else {
        console.error('Error fetching Prescriptions data:', response.statusText);
      }
    },
    addMedicalPrescription() {
      this.prescription.medical_prescriptions = [
        ...this.prescription.medical_prescriptions,
        {
          id:0,
          formulation_id: 0,
          medical_id: 0,
          prescription_id: '',
          status: '',
          quantity: '',
          notice: '',
          size: '',
          formulation: {},
          medical:{
            name:'',
            formulations:[]
          }
        }
      ];
    },
    removeMedicalReceipt(index){
      this.prescription.medical_prescriptions.splice(index, 1);
    },
    updateSelectedFormulation(index){
      console.log(this.prescription.medical_prescriptions,index)
      if (this.prescription.medical_prescriptions[index] !== undefined) {
        this.prescription.medical_prescriptions[index].formulation = {};
      }
    },
    cancelEdit() {
      this.$router.push({ name: 'PRESCRIPTION' });
    },

  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.fetchPrescriptionData(newId);
        }
      }
    }
  },

  // You can fetch the patient data when the component mounts
  mounted() {
    if(this.$route.params.id){
      this.fetchPrescriptionData();
    }

    this.fetchPatientData();
    this.fetchDoctorData();
    this.fetchMedicalData();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
/* Add your styles here, if needed */
</style>
