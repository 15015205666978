<template>
  <div class="mb-4 flex justify-between items-center">
    <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">{{$t('MEDICAL')}}12</h1>
    <RouterLink
        :to="{name:'MEDICAL_CREATE'}"
        class="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
        style="background: rgb(189 129 141 / var(--tw-bg-opacity))">
      {{$t('MEDICAL_CONFIG.CREATE')}}
    </RouterLink>
  </div>
  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">

    <TableComponent
        :columns="columns"
        :data="formattedRows"
        :sortColumn="sortColumn"
        :sortDirection="sortDirection"
        :currentPage="currentPage"
        :perPage="perPage"
        @edit="goToEdit"
        @delete="deleteItem"
        @change-page="fetchData"
    />

    <DeleteDialogComponent
        :isOpen="isModalOpen"
        @close="isModalOpen = false"
        @confirm="handleDelete()"
    />

  </div>
</template>

<script>
import axiosInstance from "@/config/axios-config";
import { formatDateDMY } from '@/helpers/dateFormat';
import DeleteDialogComponent from "@/components/dialog/DeleteDialogComponent.vue";
import {eventBus} from "@/eventBus/eventBus";
import TableComponent from "@/components/table/TableComponent.vue";
export default {
  components: {TableComponent, DeleteDialogComponent},
  data() {
    return {
      isModalOpen: false,
      idItemToDelete: false,
      searchQuery: '',
      sortColumn: null,
      sortDirection: 'asc',
      currentPage: 1,
      perPage: 5,
      rows: [],
      totalRows: 0,

      columns: [
        { key: 'name', label: this.$t('MEDICAL_CONFIG.NAME') },
        { key: 'description', label: this.$t('MEDICAL_CONFIG.DESCRIPTION') },
        { key: 'status', label: this.$t('STATUS') },
        { key: 'created_at', label: this.$t('CREATED_AT') },
      ],
    };
  },
  computed: {
    formattedRows() {
      return this.rows.map((row) => ({
        ...row,
        created_at: formatDateDMY(row.created_at), // Format `created_at` using custom function
      }));
    },
    paginatedData() {
      return this.rows;
    },
    totalPages() {
      return Math.ceil(this.totalRows / this.perPage);
    },
    startItem() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endItem() {
      const end = this.currentPage * this.perPage;
      return end > this.totalRows ? this.totalRows : end;
    },
  },
  methods: {
    formatDateDMY,
    async fetchData() {
      const response = await axiosInstance.get('doctors/medicals', {
        params: {
          page: this.currentPage,
          perPage: this.perPage,
          search: this.searchQuery,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
        },
      });
      this.rows = response.data.data;
      this.totalRows = response.data.total;
    },
    goToEdit(id) {
      this.$router.push({ name: 'MEDICAL_EDIT', params: { id: id } });
    },
    handleDelete() {
      axiosInstance.delete(`doctors/medicals/${this.idItemToDelete}`).then(response => {
        if (response && response.data) {
          this.closeModal();
          this.fetchData();
          eventBus.showToast(response.data.message, "success");
        }
      })
      .catch(error => {
        eventBus.showToast(error, "error");
      })
      this.idItemToDelete = '';
    },
    closeModal() {
      this.isModalOpen = false;
    },
    deleteItem(id) {
      this.idItemToDelete = id;
      this.isModalOpen = true;
    },
    actionBulk(event){
      const selectedValue = event.target.value;
      if (selectedValue === 'delete') {
        const selectedIds = this.paginatedData
            .filter((_, index) => this.selectedRows[index])
            .map(item => item.id);
        axiosInstance.post(`doctors/medicals_bulk_delete`, {
          ids:selectedIds
        })
        .then(response => {
          if (response && response.data) {
            eventBus.showToast(response.data.message, "success");
            this.fetchData();
          }
        })
        .catch(error => {
          eventBus.showToast(error, "error");
        })

      }

    }
  },
  mounted() {
    this.fetchData(); // Fetch data when component mounts
  },
};
</script>

<style scoped>
/* Custom table styles, if needed */
</style>