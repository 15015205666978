<template>
  <nav class="fixed z-30 w-full border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
    <div class="px-3 py-3 lg:px-5 lg:pl-3">
      <div class="flex items-center justify-between">
        <div class="flex items-center justify-start">
          <button @click="toggleSidebarMode" id="toggleSidebarMobile" aria-expanded="true" aria-controls="sidebar"
                  class="p-2 text-gray-600 rounded
          cursor-pointer hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700
          focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700
          dark:hover:text-white">
            <font-awesome-icon :icon="isSidebarCompact ? ['fas', 'bars'] : ['fas', 'align-left']"/>
          </button>
          <a href="" class="flex ml-2 md:mr-24">
            <span
                class="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">Online Clinic</span>
          </a>
        </div>
        <div class="flex items-center">
          <!-- LocaleSwitcher -->
          <LocaleSwitcher></LocaleSwitcher>

          <!-- Dropdown Menu -->
          <div class="relative flex items-center ml-3">
            <button ref="dropdownButton"
                    type="button"
                    class="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                    @click="toggleDropdown">
              <span class="sr-only">Open user menu</span>
              <img class="w-8 h-8 rounded-full"
                   src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                   alt="user photo">
            </button>

            <!-- Dropdown Menu Content -->
            <div v-show="isDropdownOpen"
                 class="z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                 ref="dropdown"
                 :style="dropdownStyles">
              <div class="px-4 py-3">
                <p class="text-sm text-gray-900 dark:text-white">{{ getDoctorProfile.name }}</p>
                <p class="text-sm font-medium text-gray-900 truncate dark:text-gray-300">{{ getDoctorProfile.email }}</p>
              </div>
              <ul class="py-1">
                <li>
                  <RouterLink to="/dashboard"
                              class="flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700">
                    <span class="ml-3">{{ $t('SIDEBAR_CONFIG.DASHBOARD') }}</span>
                  </RouterLink>
                </li>
                <li>
                  <a href="#" @click.prevent="logout"
                     class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">Sign
                    out</a>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import LocaleSwitcher from "@/components/layout/LocaleSwitcher.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import axiosInstance from "@/config/axios-config";

export default {
  name: "NavbarComponent",
  components: {LocaleSwitcher},
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  watch: {
    // Reinitialize dropdown when the route changes
    $route() {
      this.closeDropdown();
    },
  },
  computed: {
    ...mapGetters(['isSidebarCompact', 'getDoctorProfile']),
    ...mapState({
      user: (state) => state.doctorProfile,
    }),
    dropdownStyles() {
      // Default styles, adjust if necessary
      return {
        position: 'absolute',
        top: '100%',
        zIndex: 50,
      };
    },
  },
  methods: {
    ...mapActions(['toggleSidebarMode']),
    async logout() {
      try {
        const token = localStorage.getItem("auth");

        if (token) {
          await axiosInstance.post('/logout', {}, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          localStorage.removeItem("auth");
          localStorage.removeItem("doctorUser");
          this.$router.push({name: 'Login'});
        } else {
          this.$router.push({name: 'Login'});
        }
      } catch (error) {
        console.error("Logout failed", error);
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;

      // Wait for next DOM update before calculating dropdown position
      this.$nextTick(() => {
        this.updateDropdownPosition();
      });
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    updateDropdownPosition() {
      // Ensure the dropdownButton is available before calculating position
      const button = this.$refs.dropdownButton;
      if (button && this.$refs.dropdown) {
        const dropdownWidth = 200; // Estimated width of the dropdown
        const viewportWidth = window.innerWidth;
        const dropdownLeft = button.getBoundingClientRect().left;

        // Calculate if the dropdown will overflow on the right
        const isOverflowingRight = (dropdownLeft + dropdownWidth) > viewportWidth;

        // Set dynamic styles for dropdown positioning
        if (isOverflowingRight) {
          this.$refs.dropdown.style.right = '0'; // Align dropdown to the right side
          this.$refs.dropdown.style.left = 'auto'; // Disable left positioning
        } else {
          this.$refs.dropdown.style.left = '0'; // Align dropdown to the left side
          this.$refs.dropdown.style.right = 'auto'; // Disable right positioning
        }
      }
    },
    handleClickOutside(event) {
      if (!this.$refs.dropdown.contains(event.target) &&
          !this.$refs.dropdownButton.contains(event.target)) {
        this.closeDropdown();
      }
    },
  },
  mounted() {
    // Ensure the dropdown button reference is available
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
}
</script>

<style scoped>
/* Add any scoped styling if necessary */
</style>
