import { createI18n } from 'vue-i18n';
import en from '../locales/en.json';
import de from '../locales/de.json';

const savedLanguage = localStorage.getItem('doctorLanguage') || 'en';
// Create the i18n instance
const i18n = createI18n({
    locale: savedLanguage, // Default locale (initially set, will be overwritten by Vuex)
    fallbackLocale: 'en', // Fallback locale
    messages: {
        en,
        de,
    },
});

// Function to set locale from Vuex store
export const setI18nLocale = (locale) => {
    i18n.global.locale = locale;
};

export default i18n;
