<template>
  <div class="w-full mb-1">
    <div class="mb-4">
      <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
        {{$t('PATIENT_CONFIG.EDIT')}}
      </h1>
    </div>
    <div class="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3 mb-4">
      <div class="p-4 border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6">
        <form @submit.prevent="updatePatient" class="max-w-md mx-auto">
          <div class="grid md:grid-cols-2 md:gap-6">
            <div class="relative z-0 w-full mb-5 group">
              <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{$t('PATIENT_CONFIG.FIRST_NAME')}}
              </label>
              <input type="text" v-model="user.first_name" id="first_name"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     placeholder=" " required />
            </div>
            <div class="relative z-0 w-full mb-5 group">
              <label for="last_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{$t('PATIENT_CONFIG.LAST_NAME')}}
              </label>
              <input type="text" v-model="user.last_name" id="last_name"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     placeholder=" " required />
            </div>
          </div>
          <div class="relative z-0 w-full mb-5 group">
            <label for="birthday" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{$t('PATIENT_CONFIG.BIRTHDAY')}}
            </label>
            <input type="date" v-model="user.birthdate" id="birthday"
                   class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                   required />
          </div>
          <div class="grid md:grid-cols-2 md:gap-6">
            <div class="relative z-0 w-full mb-5 group">
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{$t('PATIENT_CONFIG.E_MAIL')}}
              </label>
              <input type="email" v-model="user.email" id="email"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     placeholder=" " required />
            </div>

            <div class="relative z-0 w-full mb-5 group">
              <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{$t('PATIENT_CONFIG.MOBIL')}}
              </label>
              <input type="tel" v-model="user.phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" id="phone"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     placeholder="123-456-7890" required />
            </div>
          </div>

          <div class="relative z-0 w-full mb-5 group">
            <label for="address" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{$t('PATIENT_CONFIG.ADDRESS')}}
            </label>
            <input type="text" v-model="user.address" id="address"
                   class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                   placeholder=" " required />
          </div>

          <div class="relative z-0 w-full mb-5 group text-right">
            <button type="submit" class="mr-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              {{$t('UPDATE')}}
            </button>
            <button type="button" @click="cancelEdit" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
              {{$t('CANCEL')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/config/axios-config";
export default {
  name: 'UserEditComponent',

  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        birthdate: '',
        email: '',
        phone: '',
        address: '',
      },
    };
  },

  methods: {
    async updateUser() {
      try {
        const response = await fetch(`api/users/${this.user.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.user),
        });

        if (response.ok) {
          // Handle successful update (e.g., redirect or show a success message)
          console.log('Patient updated successfully!');
          this.$router.push({ name: 'PatientList' }); // Example redirect to patient list
        } else {
          // Handle errors
          console.error('Error updating patient:', response.statusText);
        }
      } catch (error) {
        console.error('Error updating patient:', error);
      }
    },

    async fetchUserData() {
      // Fetch existing patient data to pre-fill the form
      const response = await axiosInstance.get(`doctors/users/${this.$route.params.id}`);
      if (response.data) {
        this.user = response.data.data;
      } else {
        console.error('Error fetching patient data:', response.statusText);
      }
    },

    cancelEdit() {
      // Logic for canceling the edit, e.g., redirecting back to the patient list
      this.$router.push({ name: 'Users' });
    },
  },

  // You can fetch the patient data when the component mounts
  mounted() {
    this.fetchUserData();
  },


};
</script>

<style scoped>
/* Add your styles here, if needed */
</style>
