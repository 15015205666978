<template>
  <aside
      id="sidebar"
      v-if="isSidebarOpen"
      :class="[
      'fixed top-0 left-0 z-20 flex flex-col h-full pt-16 duration-75 lg:flex transition-width',
      isSidebarCompact ? 'w-16' : 'w-64'
    ]"
      aria-label="Sidebar"
  >
    <div class="relative flex flex-col flex-1 min-h-0 pt-0 border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <div class="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
        <div class="flex-1 px-3 space-y-1 divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
          <ul class="pb-2 space-y-2">
            <li>
              <RouterLink to="/dashboard" :class="[
                'flex items-center p-2 text-base rounded-lg hover:bg-gray-400 group dark:hover:bg-gray-700',
                isActiveRoute('/dashboard') ? 'active-sidebar-item' : 'text-gray-900 dark:text-gray-200'
              ]">
                <font-awesome-icon :icon="['fas', 'table-list']" />
                <span class="ml-3" v-if="!isSidebarCompact" >{{$t('SIDEBAR_CONFIG.DASHBOARD')}}</span>
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/schedules" :class="[
                'flex items-center p-2 text-base rounded-lg hover:bg-gray-400 group dark:hover:bg-gray-700',
                isActiveRoute('/schedules') ? 'active-sidebar-item' : 'text-gray-900 dark:text-gray-200'
              ]">
                <font-awesome-icon :icon="['fas', 'calendar-week']" />
                <span class="ml-3" v-if="!isSidebarCompact">{{ $t('SIDEBAR_CONFIG.SCHEDULES') }}</span>
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/services" :class="[
                'flex items-center p-2 text-base rounded-lg hover:bg-gray-400 group dark:hover:bg-gray-700',
                isActiveRoute('/services') ? 'active-sidebar-item' : 'text-gray-900 dark:text-gray-200'
              ]">
                <font-awesome-icon :icon="['fas', 'briefcase-medical']" />
                <span class="ml-3" v-if="!isSidebarCompact">{{ $t('SIDEBAR_CONFIG.SERVICES') }}</span>
              </RouterLink>
            </li>

            <li>
              <RouterLink to="/medicals" :class="[
                'flex items-center p-2 text-base rounded-lg hover:bg-gray-400 group dark:hover:bg-gray-700',
                isActiveRoute('/medicals') ? 'active-sidebar-item' : 'text-gray-900 dark:text-gray-200'
              ]">
                <font-awesome-icon :icon="['fas', 'pills']" />
                <span class="ml-3" v-if="!isSidebarCompact">{{$t('SIDEBAR_CONFIG.MEDICAL')}}</span>
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/patients" :class="[
                'flex items-center p-2 text-base rounded-lg hover:bg-gray-400 group dark:hover:bg-gray-700',
                isActiveRoute('/patients') ? 'active-sidebar-item' : 'text-gray-900 dark:text-gray-200'
              ]">
                <font-awesome-icon :icon="['fas', 'hospital-user']" />
                <span class="ml-3" v-if="!isSidebarCompact">{{$t('SIDEBAR_CONFIG.PATIENTS')}}</span>
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/prescriptions" :class="[
                'flex items-center p-2 text-base rounded-lg hover:bg-gray-400 group dark:hover:bg-gray-700',
                isActiveRoute('/prescriptions') ? 'active-sidebar-item' : 'text-gray-900 dark:text-gray-200'
              ]">
                <font-awesome-icon :icon="['fas', 'file-medical']" />
                <span class="ml-3" v-if="!isSidebarCompact">{{$t('SIDEBAR_CONFIG.PRESCRIPTIONS')}}</span>
              </RouterLink>
            </li>
            <li>
              <RouterLink to="/users" :class="[
                'flex items-center p-2 text-base rounded-lg hover:bg-gray-400 group dark:hover:bg-gray-700',
                isActiveRoute('/users') ? 'active-sidebar-item' : 'text-gray-900 dark:text-gray-200'
              ]">
                <font-awesome-icon :icon="['fas', 'users']" />
                <span class="ml-3" v-if="!isSidebarCompact">{{$t('SIDEBAR_CONFIG.USERS')}}</span>
              </RouterLink>
            </li>
          </ul>

          <div class="pt-2 space-y-2">
            <RouterLink to="/settings" :class="[
                'flex items-center p-2 text-base rounded-lg hover:bg-gray-400 group dark:hover:bg-gray-700',
                isActiveRoute('/settings') ? 'active-sidebar-item' : 'text-gray-900 dark:text-gray-200'
              ]">
              <font-awesome-icon :icon="['fas', 'gear']" />
              <span class="ml-3" v-if="!isSidebarCompact">{{$t('SIDEBAR_CONFIG.SETTINGS')}}</span>
            </RouterLink>
            <RouterLink to="/zooms" :class="[
                'flex items-center p-2 text-base rounded-lg hover:bg-gray-400 group dark:hover:bg-gray-700',
                isActiveRoute('/zooms') ? 'active-sidebar-item' : 'text-gray-900 dark:text-gray-200'
              ]">
              <font-awesome-icon :icon="['fas', 'video']" />
              <span class="ml-3" v-if="!isSidebarCompact">{{$t('SIDEBAR_CONFIG.ZOOM')}}</span>
            </RouterLink>
            <RouterLink to="/profile" :class="[
                'flex items-center p-2 text-base rounded-lg hover:bg-gray-400 group dark:hover:bg-gray-700',
                isActiveRoute('/profile') ? 'active-sidebar-item' : 'text-gray-900 dark:text-gray-200'
              ]">
              <font-awesome-icon :icon="['fas', 'user']" />
              <span class="ml-3" v-if="!isSidebarCompact">{{$t('SIDEBAR_CONFIG.PROFILE')}}</span>
            </RouterLink>
          </div>
        </div>
      </div>

    </div>
  </aside>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'SidebarComponent',
  components: {},
  // Optional props or data
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['isSidebarOpen','isSidebarCompact']) // maps Vuex state to component property
  },
  methods: {
    isActiveRoute(baseRoute) {
      // Checks if the current path starts with the base route
      return this.$route.path.startsWith(baseRoute);
    }
  }
};
</script>

<style scoped>
/* Scoped styles specific to the header */
</style>
