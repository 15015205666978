<template>
  <div
      v-if="isOpen"
      class="fixed inset-0 z-50 flex items-center justify-center opacity-90 bg-white"
  >
    <div class="bg-white rounded-lg shadow-lg p-6 w-96">
      <h2 class="text-lg font-semibold mb-4">{{$t('DELETE_CONFIG.CONFIRM')}}</h2>
      <p class="mb-4">{{$t('DELETE_CONFIG.CONFIRM_TEXT')}}</p>
      <div class="flex justify-end">
        <button
            @click="closeModal"
            class="mr-2 bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
        >
          Cancel
        </button>
        <button
            @click="confirmDelete"
            class="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
        >
          Delete
        </button>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    confirmDelete() {
      this.$emit("confirm");
    },
  },
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
