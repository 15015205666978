<template>
  <div
      class="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden
      bg-opacity-50"
      @click="closeModal"
  >
    <div
        class="bg-white p-6 max-w-md w-full relative border border-gray-200 rounded-lg shadow dark:bg-gray-800"
        @click.stop
    >
    <header class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-bold">Appointment Details</h2>
      <button
          type="button"
          @click="closeModal"
          class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          aria-label="Close"
      >
        <font-awesome-icon :icon="['fas', 'xmark']" size="xl"/>
      </button>
    </header>

    <div class="p-4 pl-5 border border-gray-200 rounded-lg shadow dark:bg-gray-800">
      <div class="">
        <p class="mb-2"><strong>Date:</strong> {{ formatDate(schedule.date) }}</p>
        <ol class="relative border-s border-gray-200 dark:border-gray-700">
          <li class="mb-10 ms-6">
        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
          <font-awesome-icon :icon="['fas', 'hourglass-start']" />
        </span>
            <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">{{ formatTimeHI(schedule.date_start_time) }} </h3>
            <div v-if="appointment && appointment.service">
              <div v-for="translation in appointment.service.translations_by_locale" :key="translation.id">
                <h5 class="text-lg font-semibold text-gray-900 dark:text-white">{{translation.title}}</h5>
                <h6 class="text-sm font-semibold text-gray-900 dark:text-white pl-2">{{translation.description}}</h6>
              </div>
            </div>
          </li>
          <li class="mb-10 ms-6">
        <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
          <font-awesome-icon :icon="['fas', 'hourglass-end']" />
        </span>
            <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">{{ formatTimeHI(schedule.date_end_time) }}</h3>
          </li>
        </ol>
        <p><strong>Status:</strong> {{ schedule.status }}</p>
      </div>

      <div class="" v-if="appointment && appointment.user">
        <p ><strong>Patient:</strong></p>
        <div class="flex items-center">

          <div class="flex-1 min-w-0 ms-4">
            <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
              {{ appointment.user.first_name }} {{ appointment.user.last_name }}
            </p>
            <p class="text-sm text-gray-500 truncate dark:text-gray-400">
              Email: {{ appointment.user.email }}
            </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
            {{appointment.service.price}} €
          </div>
        </div>
      </div>
      <div class="" v-if="appointment">
        <p ><strong>Zoom:</strong></p>
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <font-awesome-icon :icon="['fas', 'video']" />
          </div>
          <div class="flex-1 min-w-0 ms-4">
            <p class="text-sm font-medium text-gray-900  dark:text-white">
              {{ appointment.zoom_link }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <footer class="mt-6">
      <button @click="closeModal(schedule.id)" type="button" class="text-white text-xs bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br
              focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg px-5 py-2.5 text-center me-2 mb-2">
        <span>{{$t('CLOSE')}}</span>
      </button>
      <button @click="goToEdit(schedule.id)" type="button" class="text-white text-xs bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br
              focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg px-5 py-2.5 text-center me-2 mb-2">
        <span>{{$t('EDIT')}}</span>
      </button>
      <button type="button" @click="closeModal()" class="text-white text-xs bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4
              focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80
              font-medium rounded-lg px-5 py-2.5 text-center me-2 mb-2">{{$t('DELETE')}}</button>
    </footer>
  </div>
  </div>
</template>

<script>
import {formatDateDMY,formatTimeHI} from "@/helpers/dateFormat";
import {mapGetters} from "vuex";

export default {
  props: {
    schedule: {
      type: Object,
      required: true,
    },
    appointment: {
      type: Object,
      required: true,
      user:{}
    },
  },
  computed: {
    ...mapGetters(['getDoctorLanguage']),
  },
  methods: {
    formatTimeHI,
    formatDateDMY,
    closeModal() {
      this.$emit('close');
    },
    goToEdit(id) {
      this.$router.push({ name: 'SCHEDULE_EDIT', params: { id: id } });
    },
    formatDate(date) {
      const d = new Date(date);
      return d.toLocaleDateString(); // Adjust this to your desired format
    },
  },
};
</script>

<style scoped>
/* Additional styles if needed */
</style>
