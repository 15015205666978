<template>
  <div class="w-full mb-1">
    <div class="mb-4">
      <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
        {{$t('PROFILE_CONFIG.MY_PROFILE')}}
      </h1>
      <div class="p-4 border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6">
        <div class="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3 grid-cols-3">
          <div class="">
            <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
              {{$t('PROFILE_CONFIG.PERSONAL_INFORMATION')}}
            </h1>
            <span class="text-base font-semibold text-gray-900 sm:text-base dark:text-white">
              {{$t('PROFILE_CONFIG.MANAGE_PERSONAL_INFORMATION')}}
            </span>
          </div>
          <div class="col-span-2 border border-gray-200 rounded-lg shadow-sm px-4 pt-4">
            <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-0 w-full mb-5 group">
                <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  {{$t('PATIENT_CONFIG.FIRST_NAME')}}
                </label>
                <input type="text" v-model="user.first_name" id="first_name"
                       class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                       placeholder=" " required />
                <p v-if="errors['user.first_name']" class="mt-2 text-sm text-red-600 dark:text-red-500">
                  <span v-for="(error, index) in errors['user.first_name']" :key="index">{{ error }}</span>
                </p>
              </div>
              <div class="relative z-0 w-full mb-5 group">
                <label for="last_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  {{$t('PATIENT_CONFIG.LAST_NAME')}}
                </label>
                <input type="text" v-model="user.last_name" id="last_name"
                       class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                       placeholder=" " required />
                <p v-if="errors['user.last_name']" class="mt-2 text-sm text-red-600 dark:text-red-500">
                  <span v-for="(error, index) in errors['user.last_name']" :key="index">{{ error }}</span>
                </p>
              </div>
            </div>
            <div class="relative z-0 w-full mb-5 group">
              <label for="birthday" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{$t('PATIENT_CONFIG.BIRTHDAY')}}
              </label>
              <input type="date" v-model="user.birthdate" id="birthday"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     required />
              <p v-if="errors['user.birthdate']" class="mt-2 text-sm text-red-600 dark:text-red-500">
                <span v-for="(error, index) in errors['user.birthdate']" :key="index">{{ error }}</span>
              </p>
            </div>
            <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-0 w-full mb-5 group">
                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  {{$t('PATIENT_CONFIG.E_MAIL')}}
                </label>
                <input type="email" v-model="user.email" id="email"
                       class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                       placeholder=" " required />
                <p v-if="errors['user.email']" class="mt-2 text-sm text-red-600 dark:text-red-500">
                  <span v-for="(error, index) in errors['user.email']" :key="index">{{ error }}</span>
                </p>
              </div>

              <div class="relative z-0 w-full mb-5 group">
                <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  {{$t('PATIENT_CONFIG.MOBIL')}}
                </label>
                <input type="tel" v-model="user.phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" id="phone"
                       class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                       placeholder="123-456-7890" required />
              </div>
            </div>

            <div class="relative z-0 w-full mb-5 group">
              <label for="address" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{$t('PATIENT_CONFIG.ADDRESS')}}
              </label>
              <input type="text" v-model="user.address" id="address"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     placeholder=" " required />
            </div>
            <div class="relative z-0 w-full mb-5 group text-right">
              <button type="button" @click="updateProfile" class="mr-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
               rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                {{$t('UPDATE')}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full mb-1">
    <div class="mb-4">
      <div class="p-4 border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6">

        <div class="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3 grid-cols-3">
          <div class="...">
            <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
              {{$t('PROFILE_CONFIG.PASSWORD')}}
            </h1>
            <span class="text-sm font-semibold text-gray-900 sm:text-sm dark:text-white">
              {{$t('PROFILE_CONFIG.PASSWORD_REQUIREMENT')}}
            </span>
          </div>
          <div class="col-span-2 border border-gray-200 rounded-lg shadow-sm px-4 pt-4">
            <div class="relative z-0 w-full mb-5 group">
              <label for="current_password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{$t('PROFILE_CONFIG.CURRENT_PASSWORD')}}
              </label>
              <input type="password" v-model="password.current_password" id="current_password"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     required />
              <p v-if="errors['password.current_password']" class="mt-2 text-sm text-red-600 dark:text-red-500">
                <span v-for="(error, index) in errors['password.current_password']" :key="index">{{ error }}</span>
              </p>
            </div>
            <div class="relative z-0 w-full mb-5 group">
              <label for="floating_password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{$t('PROFILE_CONFIG.NEW_PASSWORD')}}
              </label>
              <input type="password" v-model="password.new_password" id="floating_password"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     required />
              <p v-if="errors['password.new_password']" class="mt-2 text-sm text-red-600 dark:text-red-500">
                <span v-for="(error, index) in errors['password.new_password']" :key="index">{{ error }}</span>
              </p>
            </div>
            <div class="relative z-0 w-full mb-5 group">
              <label for="floating_repeat_password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{$t('PROFILE_CONFIG.NEW_PASSWORD_CONFIRM')}}
              </label>
              <input type="password" v-model="password.new_password_confirm" id="floating_repeat_password"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     required />
              <p v-if="errors['password.new_password_confirm']" class="mt-2 text-sm text-red-600 dark:text-red-500">
                <span v-for="(error, index) in errors['password.new_password_confirm']" :key="index">{{ error }}</span>
              </p>
            </div>
            <div class="relative z-0 w-full mb-5 group text-right">
              <button type="button" @click="updatePassword" class="mr-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                {{$t('UPDATE')}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full mb-1">
    <div class="mb-4">
      <div class="p-4 border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6">

        <div class="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3 grid-cols-3">

          <div class="...">
            <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
              {{$t('PROFILE_CONFIG.TWO_FACTOR_AUTHENTICATION')}}
            </h1>
            <span class="text-sm font-semibold text-gray-900 sm:text-sm dark:text-white">
              {{$t('PROFILE_CONFIG.MANAGE_TWO_FACTOR_AUTH')}}
            </span>
          </div>
          <div class="col-span-2 border border-gray-200 rounded-lg shadow-sm px-4 pt-4">
            <div class="relative z-0 w-full mb-5 group">
              <span>
                {{$t('PROFILE_CONFIG.TWO_FACTOR_AUTH_ENABLED')}}
              </span>
            </div>
            <div class="relative z-0 w-full mb-5 group text-right">
              <button type="submit" class="mr-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                {{$t('UPDATE')}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/config/axios-config";
import {eventBus} from "@/eventBus/eventBus";
export default {
  name: 'ProfileEditComponent',

  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        birthdate: '',
        email: '',
        new_password: '',
        new_password_confirm: '',
        current_password: '',
        phone: '',
        address: '',
      },
      password: {
        new_password: '',
        new_password_confirm: '',
        current_password: '',
      },

      errors:{}
    };
  },

  methods: {
    async updateUser() {
      try {
        const response = await fetch(`api/users/${this.user.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.user),
        });

        if (response.ok) {
          // Handle successful update (e.g., redirect or show a success message)
          console.log('Patient updated successfully!');
          this.$router.push({ name: 'PatientList' }); // Example redirect to patient list
        } else {
          // Handle errors
          console.error('Error updating patient:', response.statusText);
        }
      } catch (error) {
        console.error('Error updating patient:', error);
      }
    },

    async fetchProfileData() {
      // Fetch existing patient data to pre-fill the form
      const response = await axiosInstance.get(`doctors/profiles/`);
      if (response.data) {
        this.user = response.data.data;
      } else {
        console.error('Error fetching patient data:', response.statusText);
      }
    },
    updateProfile(){
      axiosInstance.put(`doctors/update-profile`,{
        'user' :this.user,
      }).then(response => {
        if (response && response.data) {
          this.errors = {}
          eventBus.showToast(response.data.message, "success");
        }
      })
      .catch(error => {
        if(error && error.response.data){
          this.errors =error.response.data.errors;
          eventBus.showToast(error.response.data.message, "error");
        }
      })
    },
    updatePassword(){
      axiosInstance.put(`doctors/update-profile-password`,{
        'password' :this.password,
      }).then(response => {
        if (response && response.data) {
          this.errors = {}
          this.password =  {
            new_password: '',
                new_password_confirm: '',
                current_password: '',
          };
          eventBus.showToast(response.data.message, "success");
        }
      })
      .catch(error => {
        if(error && error.response.data){
          this.errors =error.response.data.errors;
          eventBus.showToast(error.response.data.message, "error");
        }
      })
    },

    cancelEdit() {
      // Logic for canceling the edit, e.g., redirecting back to the patient list
      this.$router.push({ name: 'Users' });
    },
  },

  // You can fetch the patient data when the component mounts
  mounted() {
    this.fetchProfileData();
  },


};
</script>

<style scoped>
/* Add your styles here, if needed */
</style>
