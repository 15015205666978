<template>
  <div>
    <h6 class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {{$t('FORMULATION_CONFIG.FORMULATIONS')}}
    </h6>
    <div v-if="formulations.length > 0" class="w-full p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
      <div v-for="(formulation, index) in formulations" :key="index" class="flex items-center mb-4">
        <div class="flex-grow">
          <label :for="'formulationName' + index" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            {{$t('FORMULATION_CONFIG.FORMULATION')}}
          </label>
          <input
              type="text"
              v-model="formulation.name"
              :id="'formulationName' + index"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="Enter formulation name"
          />
        </div>
        <button type="button"
                @click="removeFormulation(index)"
                class="mt-10 ml-2 text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
          {{$t('FORMULATION_CONFIG.REMOVE')}}
        </button>
      </div>
    </div>

    <div class="text-center">
      <button type="button"
              @click="addFormulation"
              class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
        {{$t('FORMULATION_CONFIG.ADD')}}
      </button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    formulations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      internalFormulations: this.formulations.slice(), // Create an internal copy of the formulations
      medical_id: this.$route.params.id
    };
  },
  methods: {
    addFormulation() {
      const newFormulation = {name: '', type: '',status:'ACTIVE',medical_id:this.medical_id,id:0};
      this.internalFormulations.push(newFormulation);
      this.$emit('update:formulations', this.internalFormulations); // Emit the updated formulations
    },
    removeFormulation(index) {
      this.internalFormulations.splice(index, 1);
      this.$emit('update:formulations', this.internalFormulations); // Emit the updated formulations
    },
  },
  watch: {
    formulations: {
      immediate: true,
      handler(newFormulations) {
        this.internalFormulations = newFormulations.slice(); // Sync with the parent prop
      },
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
