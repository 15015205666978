import { createStore } from 'vuex'
const savedDoctorLanguage = localStorage.getItem('doctorLanguage') || 'en';
const doctorProfile = JSON.parse(localStorage.getItem('doctorUser')) || {};
const store = createStore({
    state: {
        doctorLanguage: savedDoctorLanguage,
        isAuthenticated: !!localStorage.getItem('auth'),
        isSidebarOpen: true,
        isSidebarCompact: false,
        doctorProfile:doctorProfile
    },
    mutations: {
        setDoctorLanguage(state, lang) {
            state.doctorLanguage = lang; // Mutation to change the language
        },
        login(state) {
            state.isAuthenticated = true;
        },
        logout(state) {
            state.isAuthenticated = false;
        },
        setProfile(state,user) {
            state.doctorProfile = user;
        },
        TOGGLE_SIDEBAR(state) {
            state.isSidebarOpen = !state.isSidebarOpen;
        },
        TOGGLE_SIDEBAR_MODE(state) {
            state.isSidebarCompact = !state.isSidebarCompact;
        }
    },
    actions: {
        changeDoctorLanguage({ commit }, lang) {
            commit('setDoctorLanguage', lang);
        },
        login({ commit }, token) {
            localStorage.setItem('auth', token);
            commit('login');
        },
        logout({ commit }) {
            localStorage.removeItem('auth');
            localStorage.removeItem('doctorUser');
            commit('logout');
        },
        setProfile({ commit }, user) {
            localStorage.setItem('doctorUser', JSON.stringify(user));
            commit('setProfile', user);
        },
        toggleSidebar({ commit }) {
            commit('TOGGLE_SIDEBAR');
        },
        toggleSidebarMode({ commit }) {
            commit('TOGGLE_SIDEBAR_MODE');
        }
    },
    getters: {
        getDoctorLanguage(state) {
            return state.doctorLanguage;
        },
        getDoctorProfile(state) {
            return state.doctorProfile;
        },

        isAuthenticated: (state) => state.isAuthenticated,
        isSidebarOpen: (state) => state.isSidebarOpen,
        isSidebarCompact: (state) => state.isSidebarCompact
    }
});

export default store;
