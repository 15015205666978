
import MedicalListsComponent from "@/components/medicals/MedicalListsComponent.vue";
import PatientListsComponent from "@/components/patients/PatientListsComponent.vue";
import UserListsComponent from "@/components/users/UserListsComponent.vue";
import MedicalCreateComponent from "@/components/medicals/MedicalCreateComponent.vue";
import DashboardComponent from "@/components/dashboard/DashboardComponent.vue";
import PatientFormComponent from "@/components/patients/PatientFormComponent.vue";
import MedicalEditComponent from "@/components/medicals/MedicalEditComponent.vue";
import ScheduleListsComponent from "@/components/schedules/ScheduleListsComponent.vue";
import ScheduleCreateComponent from "@/components/schedules/ScheduleCreateComponent.vue";
import PrescriptionListsComponent from "@/components/prescriptions/PrescriptionListsComponent.vue";
import LoginComponent from "@/components/login/LoginComponent.vue";
import UserEditComponent from "@/components/users/UserEditComponent.vue";
import ProfileEditComponent from "@/components/profile/ProfileEditComponent.vue";
import ServiceListsComponent from "@/components/services/ServiceListsComponent.vue";
import ServiceCreateComponent from "@/components/services/ServiceCreateComponent.vue";
import ServiceEditComponent from "@/components/services/ServiceEditComponent.vue";
import PrescriptionFormComponent from "@/components/prescriptions/PrescriptionFormComponent.vue";
import ScheduleFormComponent from "@/components/schedules/ScheduleFormComponent.vue";
import SettingFormComponent from "@/components/settings/SettingFormComponent.vue";
import ZoomSettingFormComponent from "@/components/zoom/ZoomSettingFormComponent.vue";
export default [
    {
        path: '/dashboard',
        component: DashboardComponent,
        name: 'Dashboard',
        meta: {
            title: 'Dashboard',
            requiresAuth: true
        }
    },
    {
        path: '/',
        component: DashboardComponent,
        name: 'Home',
        meta: {
            title: 'Dashboard',
            requiresAuth: true
        }
    },
    {
        path: '/schedules',
        component: ScheduleListsComponent,
        name: 'SCHEDULE',
        meta: {
            title: 'SCHEDULE',
            requiresAuth: true
        },

    },
    {
        path: '/schedules/create',
        component: ScheduleCreateComponent,
        name: 'SCHEDULE_CREATE',
        meta: {
            title: 'SCHEDULE CREATE',
            requiresAuth: true
        },

    },
    {
        path: '/schedules/edit/:id',
        component: ScheduleFormComponent,
        name: 'SCHEDULE_EDIT',
        meta: {
            title: 'SCHEDULE.EDIT',
            requiresAuth: true
        }
    },
    {
        path: '/medicals',
        component: MedicalListsComponent,
        name: 'MedicationList',
        meta: {
            title: 'MEDICAL',
            requiresAuth: true
        },

    },
    {
        path: '/medicals/create',
        component: MedicalCreateComponent,
        name: 'MEDICAL_CREATE',
        meta: {
            title: 'MEDICAL',
            requiresAuth: true
        },

    },
    {
        path: '/medicals/edit/:id',
        component: MedicalEditComponent,
        name: 'MEDICAL_EDIT',
        meta: {
            title: 'MEDICAL.EDIT',
            requiresAuth: true
        }
    },
    {
        path: '/patients',
        component: PatientListsComponent,
        name: 'PATIENT',
        meta: {
            title: 'PATIENTS',
            requiresAuth: true
        }
    },
    {
        path: '/patients/create',
        component: PatientFormComponent,
        name: 'PATIENT_CREATE',
        meta: {
            title: 'PATIENT_CREATE',
            requiresAuth: true
        }
    },
    {
        path: '/patients/edit/:id',
        component: PatientFormComponent,
        name: 'PATIENT_EDIT',
        meta: {
            title: 'PATIENT.EDIT',
            requiresAuth: true
        }
    },
    {
        path: '/prescriptions',
        component: PrescriptionListsComponent,
        name: 'PRESCRIPTION',
        meta: {
            title: 'PRESCRIPTION',
            requiresAuth: true
        }
    },
    {
        path: '/prescriptions/create',
        component: PrescriptionFormComponent,
        name: 'PRESCRIPTION_CREATE',
        meta: {
            title: 'PRESCRIPTION CREATE',
            requiresAuth: true
        }
    },
    {
        path: '/prescriptions/edit/:id',
        component: PrescriptionFormComponent,
        name: 'PRESCRIPTION_EDIT',
        meta: {
            title: 'PRESCRIPTION Edit',
            requiresAuth: true
        }
    },
    {
        path: '/users',
        component: UserListsComponent,
        name: 'Users',
        meta: {
            title: 'Users',
            requiresAuth: true
        }
    },
    {
        path: '/users/edit/:id',
        component: UserEditComponent,
        name: 'USER_EDIT',
        meta: {
            title: 'USER.EDIT',
            requiresAuth: true
        }
    },
    {
        path: '/profile',
        component: ProfileEditComponent,
        name: 'Profile',
        meta: {
            title: 'Profile',
            requiresAuth: true
        }
    },
    {
        path: '/services',
        component: ServiceListsComponent,
        name: 'SERVICE_LIST',
        meta: {
            title: 'Service',
            requiresAuth: true
        },
    },
    {
        path: '/services/create',
        component: ServiceCreateComponent,
        name: 'SERVICE_CREATE',
        meta: {
            title: 'Service',
            requiresAuth: true
        },

    },
    {
        path: '/services/edit/:id',
        component: ServiceEditComponent,
        name: 'SERVICE_EDIT',
        meta: {
            title: 'SERVICE.EDIT',
            requiresAuth: true
        }
    },
    // {
    //     path: '/settings',
    //     component: SettingListsComponent,
    //     name: 'SETTING_LIST',
    //     meta: {
    //         title: 'Settings',
    //         requiresAuth: true
    //     },
    // },
    {
        path: '/settings',
        component: SettingFormComponent,
        name: 'SETTING_CREATE',
        meta: {
            title: 'Setting',
            requiresAuth: true
        },

    },

    {
        path: '/settings/edit/:id',
        component: SettingFormComponent,
        name: 'ZOOM_EDIT',
        meta: {
            title: 'Setting',
            requiresAuth: true
        }
    },
    {
        path: '/login',
        component: LoginComponent,
        name: 'Login',
        meta: {
            title: 'Login',
        }
    },
    {
        path: '/zooms',
        component: ZoomSettingFormComponent,
        name: 'ZOOM_CREATE',
        meta: {
            title: 'Zoom',
            requiresAuth: true
        },

    },
    { path: '/:pathMatch(.*)', name: 'bad-not-found', component: LoginComponent,meta: {
            title: 'NOTFOUND',
            requiresAuth: true
        } },
];