<template>
  <div class="border border-gray-200 rounded-lg shadow-sm">
    <!-- Calendar Header: Navigation & Week -->
    <header class="mb-4 w-full">
      <h1 class="text-3xl font-bold text-center">
        <time :datetime="currentDate.toISOString().slice(0, 7)">
          {{ currentWeekRange }}
        </time>
      </h1>
      <div class="flex justify-between items-center mt-4 p-4 bg-gray-50 border-b border-gray-200">
        <div class="flex space-x-2">
          <button
              @click="prevWeek"
              type="button"
              class="flex items-center px-4 py-2 bg-blue-500 text-white rounded shadow hover:bg-blue-600"
          >
            <font-awesome-icon class="pr-1" :icon="['fas', 'chevron-left']" />
            {{$t('AVAILABILITY_CONFIG.PRE_WEEK')}}
          </button>
          <button
              type="button"
              class="flex items-center px-4 py-2 bg-gray-200 rounded shadow hover:bg-gray-300"
              @click="goToToday"
          >
            {{$t('AVAILABILITY_CONFIG.TODAY')}}
          </button>
          <button
              @click="nextWeek"
              type="button"
              class="flex items-center px-4 py-2 bg-blue-500 text-white rounded shadow hover:bg-blue-600"
          >
            {{$t('AVAILABILITY_CONFIG.NEXT_WEEK')}}
            <font-awesome-icon class="pl-1" :icon="['fas', 'chevron-right']" />
          </button>
        </div>
        <router-link
            to="/schedules/create"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
            style="background: rgb(189 129 141 / var(--tw-bg-opacity))"
        >
          {{$t('AVAILABILITY_CONFIG.CREATE')}}
        </router-link>
      </div>
    </header>

    <!-- Header: Days of the week -->
    <div class="grid grid-cols-8 divide-x divide-gray-200 bg-gray-100">
      <div class="font-semibold p-3 text-center text-sm font-medium text-gray-600">
        <!-- Empty Cell for Time Slots -->
      </div>
      <div
          v-for="day in daysInWeek"
          :key="day"
          class="font-semibold p-3 text-center text-sm font-medium text-gray-600"
      >
        {{ formatDateDMY(day) }} {{formatDayOfWeek(day)}}
      </div>
    </div>

    <!-- Calendar with Time Slots and Days of the Week -->
    <div class="grid grid-cols-8 divide-x divide-gray-200">
      <!-- Time Slot Column -->
      <div class="grid grid-rows-13 divide-y divide-gray-200">
        <div
            v-for="time in timeSlots"
            :key="time"
            class="p-4 text-sm font-medium text-gray-500 text-center"
        >
          {{ time }}
        </div>
      </div>

      <!-- Week Days Columns -->
      <div v-for="day in daysInWeek" :key="day" class="col-span-1 border-l border-gray-300 relative">
        <div v-for="hour in timeSlots" :key="day + hour" class="h-16 border-b border-gray-300"></div>
        <div v-for="event in filteredEvents(day)" :key="event.id">
          <div
              :style="calculateEventPosition(event)"
              :class="['absolute left-0 right-0 text-white flex items-center justify-center rounded shadow-md',
              eventStatusClass(event.status),
              activeEventId === event.id ? 'border-2 border-blue-500 shadow-lg' : 'border border-transparent']"
              @click="openAppointmentDialog(event.id)"
              @mouseenter="showAppointmentDetails(event.id)">
            <p>{{ formatTimeHI(event.date_start_time) }} - {{ formatTimeHI(event.date_end_time) }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Dialog for Appointment Details -->
    <appointment-dialog
        v-if="showDialog"
        :schedule="selectedSchedule"
        :appointment="selectedAppointment"
        @close="handleCloseDialog"
    />
  </div>
</template>

<script>
import axiosInstance from "@/config/axios-config";
import {formatTimeHI, formatDateTimestamp, formatDateDMY, formatDayOfWeek} from "@/helpers/dateFormat";
import AppointmentDialog from "@/components/schedules/dialog/AppointmentDialog.vue";

export default {
  components: { AppointmentDialog },
  data() {
    return {
      currentDate: new Date(), // Tracks the current week
      selectedDay: new Date().getDate(),
      listSchedules: [],
      listAppointments: [],
      showDialog: false,
      activeEventId: null,
      selectedSchedule: null,
      selectedAppointment: null,
      timeSlots: [
        '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
        '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00'
      ], // Time slots from 07:00 to 20:00
      filter: {
        scheduleIds: [],
        serviceIds: [],
        doctorIds: [],
      },
    };
  },
  computed: {
    daysInWeek() {
      const startOfWeek = this.getStartOfWeek(this.currentDate);
      const daysArray = [];

      for (let i = 0; i < 7; i++) {
        const day = new Date(startOfWeek);
        day.setDate(startOfWeek.getDate() + i);
        daysArray.push(day);
      }

      return daysArray;
    },
    currentWeekRange() {
      const startOfWeek = this.getStartOfWeek(this.currentDate);
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);

      return `${startOfWeek.toLocaleDateString()} - ${endOfWeek.toLocaleDateString()}`;
    }
  },
  methods: {
    formatTimeHI,
    formatDateDMY,
    formatDayOfWeek,
    prevWeek() {
      this.currentDate.setDate(this.currentDate.getDate() - 7);
      this.currentDate = new Date(this.currentDate);
      this.fetchWeekSchedule();
    },
    nextWeek() {
      this.currentDate.setDate(this.currentDate.getDate() + 7);
      this.currentDate = new Date(this.currentDate);
      this.fetchWeekSchedule();
    },
    goToToday() {
      this.currentDate = new Date();
      this.fetchWeekSchedule();
    },
    getStartOfWeek(date) {
      const day = date.getDay(); // 0 is Sunday, 6 is Saturday
      const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Adjust if Sunday
      return new Date(date.setDate(diff));
    },
    async fetchWeekSchedule() {

      axiosInstance.get('doctors/schedules', {
        params: {
          perPage: 100000,
          week:this.currentDate,
        },
      })
          .then(response => {
            if (response && response.data) {
              this.listSchedules = response.data.data; // Store fetched events
              console.log(response.data.data,222);
              // Extract all `id`s and assign them to `this.filter.scheduleIds`
              this.filter.scheduleIds = response.data.data.map(appointment => appointment.id);
              this.fetchAppointment()
            }
          })
          .catch(error => {
            console.error('Failed to load events:', error);
          });
    },
    // Filter events by day
    filteredEvents(day) {
      day = formatDateTimestamp(day);
      return this.listSchedules.filter(event => event.date === day);
    },
    calculateEventPosition(event) {
      const startHour = this.timeToNumber(event.start_time);
      const endHour = this.timeToNumber(event.end_time);
      const hourHeight = 64; // Assuming each hour slot is 64px tall

      // Calculate top offset in pixels based on start time
      const topOffset = (startHour - 7) * hourHeight;

      // Calculate the event duration in hours and set the height accordingly
      const eventDuration = endHour - startHour;
      const eventHeight = eventDuration * hourHeight;

      return {
        top: `${topOffset}px`,
        height: `${eventHeight}px`
      };
    },
    // Convert time string (e.g., '10:00') to a numeric value (e.g., 10.00)
    timeToNumber(time) {
      const [hours, minutes] = time.split(':').map(Number);
      return hours + minutes / 60;
    },
    // Apply different classes for event status
    eventStatusClass(status) {
      switch (status) {
        case 'confirmed':
          return 'bg-blue-400';
        case 'booked':
          return 'bg-blue-400';
        case 'pending':
          return 'bg-yellow-400';
        case 'cancelled':
          return 'bg-red-400';
        default:
          return 'bg-gray-400';
      }
    },
    getAppointmentById(id){
      return this.listSchedules.find(event => event.id === id);
    },
    openAppointmentDialog(appointmentId) {
      this.selectedAppointment = {};
      this.activeEventId = appointmentId;
      const event = this.getAppointmentById(appointmentId);
      console.log(event,333)
      if (event) {
        this.selectedSchedule = event;
        this.selectedAppointment= this.listAppointments.find(
            (appointment) => appointment.schedule_id === event.id
        );
        this.showDialog = true;
      }
    },
    showAppointmentDetails(appointmentId) {
      const event = this.getAppointmentById(appointmentId);
      if (event) {
        console.log('Hovered event:', event);
      }
    },
    handleCloseDialog() {
      this.showDialog = false; // Close the dialog
      this.activeEventId=null;
    },
    fetchAppointment() {
      axiosInstance.get('doctors/appointments', {
        params: {
          perPage: 100000,
          week:this.currentDate,
          scheduleIds:this.filter.scheduleIds
        },
      })
          .then(response => {
            if (response && response.data) {
              this.listAppointments = response.data.data; // Store fetched events
              console.log(response.data.data,2)
            }
          })
          .catch(error => {
            console.error('Failed to load events:', error);
          });
    },

  },
  mounted() {
    this.fetchWeekSchedule(); // Initial fetch on mount
  }
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
