<template>
  <div class="w-full mb-1">
    <header class="mb-4">
      <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
        {{ $t('ZOOM_CONFIG.TITLE') }}
      </h1>
    </header>
    <div class="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3 mb-4">
      <div class="p-4 border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6">
        <form @submit.prevent="submitZoomSetting">
          <div class="mb-5">
            <label for="api_key" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{ $t('ZOOM_CONFIG.API_KEY') }}
            </label>
            <input
                type="text"
                v-model="form.api_key"
                id="api_key"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="API Key"
            />
          </div>
          <div class="mb-5">
            <label for="api_secret" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{ $t('ZOOM_CONFIG.API_SECRET') }}
            </label>
            <input
                type="text"
                v-model="form.api_secret"
                id="api_secret"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="API Secret"
            />
          </div>
          <div class="mb-5">
            <label for="account_id" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{ $t('ZOOM_CONFIG.ACCOUNT_ID') }}
            </label>
            <input
                type="text"
                v-model="form.account_id"
                id="account_id"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Account ID"
            />
          </div>

          <div class="mb-5">
            <label for="webhook_token" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{ $t('ZOOM_CONFIG.WEBHOOK_TOKEN') }}
            </label>
            <input
                type="text"
                id="webhook_token"
                v-model="form.webhook_token"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Webhook Token"
            />
          </div>
          <!-- Meeting Password -->
          <div class="mb-5">
            <label for="meeting_password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{ $t('ZOOM_CONFIG.MEETING_PASSWORD') }}
            </label>
            <input
                type="text"
                id="meeting_password"
                v-model="form.meeting_password"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Meeting Password"
            />
          </div>
          <!-- Actions -->
          <div class="flex justify-end gap-4">
            <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded-md">
              {{ $t('SAVE') }}
            </button>
            <button type="button" @click="cancelEdit" class="bg-gray-500 text-white px-4 py-2 rounded-md">
              {{ $t('CANCEL') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/config/axios-config";
import {eventBus} from "@/eventBus/eventBus";

export default {
  name: "ZoomSettingFormComponent",
  data() {
    return {
      form: {
        id:0,
        api_key: "",
        api_secret: "",
        account_id: "",
        user_id: "",
        meeting_password: "",
        webhook_token: "",
      },
    };
  },
  computed: {

  },
  methods: {
    async fetchZoomSetting() {
      try {
        const response = await axiosInstance.get(`/doctors/zoom-settings`);
        if(response.data && response.data.data){
          this.form = response.data.data;
        }
      } catch (error) {
        console.error("Error fetching hospital data:", error);
      }
    },
    async submitZoomSetting() {
      const { id } = this.form.id;
      try {
        const method = id ? "put" : "post";
        const url = id ? `/doctors/zoom-settings/${id}` : "/doctors/zoom-settings";
        const response = await axiosInstance[method](url, this.form);

        eventBus.showToast(response.data.message, "success");
      } catch (error) {
        if(error && error.response.data){
          eventBus.showToast(error.response.data.message, "error");
        }
      }
    },
    cancelEdit() {
      this.$router.push({ name: "SETTING_LIST" });
    },
  },
  mounted() {
    this.fetchZoomSetting();
  },
};
</script>


<style scoped>
/* Custom table styles, if needed */
</style>