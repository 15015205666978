<template>
  <div class="w-full mb-1">
    <div class="mb-4">
      <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
        {{$t('MEDICAL_CONFIG.CREATE')}}
      </h1>
    </div>
    <div class="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3 mb-4">
      <div class="p-4 border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6">
        <form class="">
          <div class="relative z-0 w-full mb-5 group">
            <label for="address" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{$t('PATIENT_CONFIG.NAME')}}
            </label>
            <input type="text" v-model="medical.name" id="name"
                   class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                   placeholder=" " required />
          </div>
          <div>
            <label class="inline-flex items-center mb-5 cursor-pointer">
              <input
                  type="checkbox"
                  :checked="isActive"
              @change="toggleStatus"
              class="sr-only peer"
              />
              <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300
              rounded-full peer dark:bg-gray-700 peer-checked:bg-blue-600"
              >
                <span
                    class="absolute left-0 top-0 h-6 w-11 rounded-full transition duration-200 ease-in-out"
                    :class="{'bg-blue-600': isActive, 'bg-gray-200': !isActive}"
                ></span>
                <span
                    class="absolute mt-0.5 h-5 w-5 bg-white rounded-full shadow transition duration-200 ease-in-out"
                    :class="{'translate-x-full': isActive, 'translate-x-0': !isActive}"
                ></span>
              </div>
              <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                Status: {{ medical.status }}  <!-- Display current status -->
              </span>
            </label>

          </div>
          <FormulationFormComponent v-model:formulations="medical.formulations" ref="formulationComponent" />

        </form>
      </div>
      <div class="relative z-0 w-full mb-5 group text-right">
        <button type="submit" @click="createMedical" class="mr-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          {{$t('MEDICAL_CONFIG.SAVE')}}
        </button>
        <button type="button" @click="cancelEdit" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
          {{$t('CANCEL')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/config/axios-config";
import FormulationFormComponent from "@/components/formulations/FormulationFormComponent.vue";
import {eventBus} from "@/eventBus/eventBus";
export default {
  name: 'MedicalCreateComponent',
  components: {FormulationFormComponent},
  data() {
    return {
      medical: {
        name: '',
        description: '',
        status: 'ACTIVE',
        formulations:[
        ]
      },
    };
  },

  methods: {
    createMedical() {
      axiosInstance.post(`doctors/medicals`, {
        medical:this.medical
      }).then(response => {
        if (response && response.data) {
          eventBus.showToast(response.data.message, "success");
          this.$router.push({ name: 'MEDICAL_EDIT', params: { id: response.data.medical.id } });
        }
      })
      .catch(error => {
        if(error && error.response.data){
          eventBus.showToast(error.response.data.message, "error");
        }
      })
    },
    cancelEdit() {
      this.$router.push({ name: 'MedicationList' });
    },
    toggleStatus() {
      // Toggle between "ACTIVE" and "INACTIVE"
      this.medical.status = this.isActive ? "INACTIVE" : "ACTIVE";
    },


  },
  computed: {
    isActive() {
      return this.medical.status === "ACTIVE"; // Compute the checked state based on medical.status
    },
  },

  // You can fetch the patient data when the component mounts
  mounted() {
    //this.fetchMedicalData();
  },


};
</script>

<style scoped>
/* Add your styles here, if needed */
</style>
