<template>


  <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
    <div class="flex justify-between mb-4">
      <!-- Items per page dropdown -->

      <div>
        <select @change="handleBulkAction" v-model="bulkAction" id="action_bulk" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
        focus:ring-blue-500 focus:border-blue-500 block
          w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option disabled value="">{{ $t('ACTION') }}</option>
          <option value="delete">{{ $t('DELETE') }}</option>
        </select>
      </div>
      <!-- Search and Items Per Page Controls -->
      <div class="order-last">
        <!-- Search box -->
        <div class="">
          <input
              v-model="searchQuery"
              @input="handleSearch"
              type="text"
              placeholder="Search..."
              class="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
      </div>
    </div>
    <!-- Data table -->
    <div class="overflow-x-auto shadow-md rounded-lg">
      <table class="min-w-full bg-white table-auto text-left">
        <thead class="bg-gray-50">
        <tr>
          <th
              scope="col"
              class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
          >
            <div class="flex items-center">
              <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 border-gray-300 rounded focus:ring-blue-500
                dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                dark:border-gray-600"
                     v-model="selectAll"
                     @change="toggleSelectAll">
              <label for="checkbox-all-search" class="sr-only">checkbox</label>
            </div>
          </th>
          <th v-for="column in columns" :key="column.key" @click="sortBy(column.key)"
              class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
            {{ column.label }}
            <span v-if="sortColumn === column.key">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
          </th>
          <th  class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">{{ $t('ACTION') }}</th>
        </tr>
        </thead>


        <tbody>
        <tr
            v-for="(row, index) in paginatedData"
            :key="index"
            class="border-b"
        >
          <td class="px-6 py-4">
            <div class="flex items-center">
              <input
                  :id="'checkbox-table-search-' + index"
                  type="checkbox"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  v-model="selectedRows"
                  :value="row.id"
              />
              <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
            </div>
          </td>
          <td class="px-6 py-4" v-for="column in columns" :key="column.key">{{ row[column.key] }}</td>
          <td class="px-6 py-4">
            <button @click="$emit('edit', row.id)" class="text-white text-xs bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br
              focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg px-5 py-2.5 text-center me-2 mb-2">
              {{$t('EDIT')}}</button>
            <button @click="$emit('delete', row.id)" class="text-white text-xs bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4
              focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80
              font-medium rounded-lg px-5 py-2.5 text-center me-2 mb-2">{{$t('DELETE')}}</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>


    <!-- Pagination controls -->
    <div class="flex justify-between items-center mt-4">
      <div class="pagination-controls flex justify-between mt-4">
        <select v-model="perPage" @change="handlePaginationChange" class="p-2 border rounded-md">
          <option v-for="option in [5, 10, 20, 50]" :key="option" :value="option">{{ option }}</option>
        </select>
      </div>


      <div>
        <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
          <li>
            <button
                class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                :disabled="currentPage === 1"
                @click="previousPage"
            >
              {{$t('PREVIOUS')}}
            </button>
          </li>
          <li v-for="page in totalPages" :key="page">
            <button
                :key="page"
                class="flex items-center justify-center px-3 h-8 leading-tight"
                :class="currentPage === page ? 'text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white' : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'"
                @click="goToPage(page)"
            >
              {{ page }}
            </button>
          </li>
          <li>
            <button
                class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                :disabled="currentPage === totalPages"
                @click="nextPage"
            >
              {{$t('NEXT')}}
            </button>
          </li>
        </ul>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
    columns: Array,
    initialSortColumn: { type: String, default: null },
    initialSortDirection: { type: String, default: 'asc' },
  },
  data() {
    return {
      searchQuery: '',
      sortColumn: this.initialSortColumn,
      sortDirection: this.initialSortDirection,
      bulkAction: '',
      currentPage: 1,
      perPage: 5,
      selectAll: false,
      selectedRows: []
    };
  },
  computed: {
    filteredData() {
      let filtered = this.data;

      // Search filter
      if (this.searchQuery) {
        filtered = filtered.filter((item) =>
            this.columns.some((column) =>
                String(item[column.key]).toLowerCase().includes(this.searchQuery.toLowerCase())
            )
        );
      }

      // Sorting
      if (this.sortColumn) {
        filtered.sort((a, b) => {
          const valueA = a[this.sortColumn];
          const valueB = b[this.sortColumn];

          if (valueA < valueB) return this.sortDirection === 'asc' ? -1 : 1;
          if (valueA > valueB) return this.sortDirection === 'asc' ? 1 : -1;
          return 0;
        });
      }

      return filtered;
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.perPage;
      return this.filteredData.slice(start, start + this.perPage);
    },
    totalPages() {
      return Math.ceil(this.filteredData.length / this.perPage);
    },
  },
  methods: {
    handleSearch() {
      this.currentPage = 1;
    },
    sortBy(column) {
      if (this.sortColumn === column) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortDirection = 'asc';
      }
    },
    handleBulkAction() {
      if (this.bulkAction === 'delete' && this.selectedRows.length) {
        this.$emit('bulk-delete', this.selectedRows);
        this.selectedRows = [];
        this.bulkAction = '';
      }
    },
    toggleSelectAll() {
      this.selectedRows = this.selectAll ? this.paginatedData.map((row) => row.id) : [];
    },
    handlePaginationChange() {
      this.currentPage = 1;
    },
    previousPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    goToPage(page) {
      this.currentPage = page;
    },
  },
  watch: {
    selectedRows(newSelectedRows) {
      // Update selectAll checkbox if all rows on the page are selected
      this.selectAll = newSelectedRows.length === this.paginatedData.length;
    },
  },
};
</script>

<style scoped>
.table-component {
  max-width: 100%;
}
.table-component table {
  width: 100%;
}
.pagination-controls button {
  padding: 5px 10px;
}
</style>
