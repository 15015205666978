<template>
  <div class="w-full mb-1">
    <header class="mb-4">
      <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
        {{$t('CREATE')}} {{$t('SERVICE_CONFIG.NAME')}}
      </h1>
    </header>
    <div class="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3 mb-4">
      <div class="p-4 border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6">
        <form @submit.prevent="createMedical">
          <!-- Dynamic Translation Fields -->
          <div v-for="(translation, index) in service.translations" :key="index">
            <h6 class="mt-6 block text-md font-semibold text-gray-900 dark:text-white">
              {{ $t('SERVICE_CONFIG.TRANSLATION.TITLE') }} ({{ translation.label }})
            </h6>
            <div class="mb-5 px-4">
              <label :for="'title_' + translation.label" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{ $t('SERVICE_CONFIG.TRANSLATION.TITLE') }}
              </label>
              <input
                  type="text"
                  v-model="translation.title"
                  :id="'title_' + translation.label"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  :placeholder="$t('SERVICE_CONFIG.TRANSLATION.TITLE')"
              />
            </div>

            <div class="mb-5 px-4">
              <label :for="'description_' + translation.label" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{ $t('SERVICE_CONFIG.TRANSLATION.DESCRIPTION') }}
              </label>
              <textarea
                  v-model="translation.description"
                  :id="'description_' + translation.label"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  :placeholder="$t('SERVICE_CONFIG.TRANSLATION.DESCRIPTION')"
                  rows="4"
              ></textarea>
            </div>
          </div>

          <div class="mb-5">
            <label for="price" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{$t('SERVICE_CONFIG.PRICE')}}
            </label>
            <input
                type="number"
                v-model="service.price"
                id="price"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="00"
                required
            />
          </div>

          <div class="mb-5">
            <label for="status" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              {{$t('STATUS')}}
            </label>
            <select v-model="service.status" id="status" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option value="ACTIVE">{{$t('SERVICE_CONFIG.ACTIVE')}}</option>
              <option value="INACTIVE">{{$t('SERVICE_CONFIG.INACTIVE')}}</option>
            </select>
          </div>

          <!-- Actions -->
          <div class="relative z-0 w-full mb-5 group text-right">
            <button type="submit" class="mr-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              {{$t('SAVE')}}
            </button>
            <button type="button" @click="cancelEdit" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
              {{$t('CANCEL')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/config/axios-config";
import { eventBus } from "@/eventBus/eventBus";

export default {
  name: 'ServiceCreateComponent',
  data() {
    return {
      service: {
        slug: '',
        user_id: null,
        status: 'active',
        price: null,
        duration: null,
        type: 'MAIN',
        translations: [
          { locale: 'en', title: '', description: '',label:'English' },
          { locale: 'de', title: '', description: '',label:'English' }
        ]
      }
    };
  },
  methods: {
    async createMedical() {
      try {
        const response = await axiosInstance.post(`doctors/services`, { service: this.service });
        if (response && response.data) {
          eventBus.showToast(response.data.message, "success");
          this.$router.push({ name: 'SERVICE_EDIT', params: { id: response.data.service.id } });
        }
      } catch (error) {
        if (error.response?.data) {
          eventBus.showToast(error.response.data.message, "error");
        }
      }
    },
    cancelEdit() {
      this.$router.push({ name: 'SERVICE_LIST' });
    }
  }
};
</script>

<style scoped>
</style>
