<template>
  <div class="mb-4 flex justify-between items-center">
    <h1 class="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">{{$t('PRESCRIPTION_CONFIG.NAME')}}</h1>
    <router-link
        to="/prescriptions/create"
        class="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
        style="background: rgb(189 129 141 / var(--tw-bg-opacity))">
      {{$t('PRESCRIPTION_CONFIG.CREATE')}}
    </router-link>
  </div>
  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700
    sm:p-6 dark:bg-gray-800">
      <div class="flex justify-between mb-4">
        <!-- Items per page dropdown -->
        <div>

        </div>
        <!-- Search and Items Per Page Controls -->
        <div class="order-last">
          <!-- Search box -->
          <div class="">
            <input
                v-model="searchQuery"
                @input="filterData"
                type="text"
                placeholder="Search..."
                class="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
        </div>
      </div>
      <!-- Data table -->
      <div class="overflow-x-auto shadow-md rounded-lg">
        <table class="min-w-full bg-white table-auto text-left">
          <thead class="bg-gray-50">
          <tr>
            <th
                scope="col"
                class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            >
              <div class="flex items-center">
                <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 border-gray-300 rounded focus:ring-blue-500
                dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                dark:border-gray-600"
                       v-model="allSelected"
                       @change="toggleSelectAll">
                <label for="checkbox-all-search" class="sr-only">checkbox</label>
              </div>
            </th>
            <th
                scope="col"
                class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                @click="sortData('id')"
            >
              {{$t('PRESCRIPTION_CONFIG.PATIENT')}}
              <span v-if="sortColumn === 'patient.name'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
            </th>
            <th
                scope="col"
                class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                @click="sortData('id')"
            >
              {{$t('PRESCRIPTION_CONFIG.PRACTITIONER')}}
              <span v-if="sortColumn === 'first_name'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
            </th>
            <th
                scope="col"
                class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                @click="sortData('status')"
            >
              {{$t('PRESCRIPTION_CONFIG.STATUS')}}
              <span v-if="sortColumn === 'last_name'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
            </th>

            <th
                scope="col"
                class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                @click="sortData('updated_at')"
            >
              {{$t('PRESCRIPTION_CONFIG.LAST_UPDATED')}}
              <span v-if="sortColumn === 'updated_at'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
            </th>
            <th
                scope="col"
                class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            >
              {{$t('ACTION')}}
              <span v-if="sortColumn === 'id'">{{ sortDirection === 'asc' ? '↑' : '↓' }}</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(row, index) in paginatedData"
              :key="index"
              class="border-b"
          >
            <td class="px-6 py-4">
              <div class="flex items-center">
                <input
                    :id="'checkbox-table-search-' + index"
                    type="checkbox"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    v-model="selectedRows[index]"
                    @change="updateSelectAllState"
                />
                <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
              </div>
            </td>
            <td class="px-6 py-4">{{ row.patient?.name }}</td>
            <td class="px-6 py-4">{{ row.doctor?.name}}</td>
            <td class="px-6 py-4">{{ row.status }}</td>

            <td class="px-6 py-4">{{ formatDateDMY(row.created_at) }}</td>

            <td class="px-6 py-4">
              <button @click="goToEdit(row.id)" type="button" class="text-white text-xs bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br
              focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg px-5 py-2.5 text-center me-2 mb-2">
                <span>{{$t('EDIT')}}</span>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <!-- Pagination controls -->
      <div class="flex justify-between items-center mt-4">

        <!-- Items per page dropdown -->
        <div>
          <select id="perPage" v-model="perPage" @change="updatePerPage" class="p-2 border border-gray-300 rounded-md">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <span class="p-2">
            {{ $t("SHOW_ITEM_PER_PAGE", { startItem, endItem, totalRows }) }}
        </span>
        </div>

        <div>
          <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
            <li>
              <button
                  class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  :disabled="currentPage === 1"
                  @click="previousPage"
              >
                {{$t('PREVIOUS')}}
              </button>
            </li>
            <li v-for="page in totalPages" :key="page">
              <button
                  class="flex items-center justify-center px-3 h-8 leading-tight"
                  :class="currentPage === page ? 'text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white' : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'"
                  @click="goToPage(page)"
              >
                {{ page }}
              </button>
            </li>
            <li>
              <button
                  class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  :disabled="currentPage === totalPages"
                  @click="nextPage"
              >
                {{$t('NEXT')}}
              </button>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/config/axios-config";
import { formatDateDMY } from '@/helpers/dateFormat';
export default {
  data() {
    return {
      searchQuery: '',
      sortColumn: null,
      sortDirection: 'asc',
      currentPage: 1,
      perPage: 5,
      rows: [],
      totalRows: 0,
      allSelected: false, // Manage state for 'Check All' checkbox
      selectedRows: [], // Array to track selected rows
      selectedOption: 'Last 30 days',
      options: [
        { label: 'Last day', value: 'Last day' },
        { label: 'Last 7 days', value: 'Last 7 days' },
        { label: 'Last 30 days', value: 'Last 30 days' },
        { label: 'Last month', value: 'Last month' },
        { label: 'Last year', value: 'Last year' },
      ],
    };
  },
  computed: {
    paginatedData() {
      return this.rows;
    },
    totalPages() {
      return Math.ceil(this.totalRows / this.perPage);
    },
    startItem() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endItem() {
      const end = this.currentPage * this.perPage;
      return end > this.totalRows ? this.totalRows : end;
    },
  },
  methods: {
    formatDateDMY,
    async fetchData() {
      const response = await axiosInstance.get('doctors/prescriptions', {
        params: {
          page: this.currentPage,
          perPage: this.perPage,
          search: this.searchQuery,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
        },
      });
      this.rows = response.data.data;
      this.totalRows = response.data.total;
    },
    sortData(column) {
      if (this.sortColumn === column) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortDirection = 'asc';
      }
      this.fetchData(); // Fetch sorted data
    },
    filterData() {
      this.currentPage = 1;
      this.fetchData(); // Fetch filtered data
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchData();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchData();
      }
    },
    updatePerPage() {
      this.currentPage = 1; // Reset to the first page when items per page changes
      this.fetchData(); // Fetch updated data
    },
    goToPage(page) {
      // Check if the page number is valid
      if (page < 1 || page > this.totalPages) {
        return; // Ignore if page is out of bounds
      }
      this.currentPage = page; // Set the current page to the selected page

      // You can also add logic here to fetch new data based on the current page
      // For example:
      this.fetchData();
    },
    toggleSelectAll() {
      this.selectedRows = this.paginatedData.map(() => this.allSelected); // Set all checkboxes to the value of 'allSelected'
    },
    updateSelectAllState() {
      // Update 'allSelected' state based on individual checkboxes
      this.allSelected = this.selectedRows.every((selected) => selected);
    },
    goToEdit(id) {
      this.$router.push({ name: 'PRESCRIPTION_EDIT', params: { id: id } });
    },
  },
  mounted() {
    this.fetchData(); // Fetch data when component mounts
  },
};
</script>

<style scoped>
/* Custom table styles, if needed */
</style>