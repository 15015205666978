<template>
  <NavbarComponent v-if="isAuthenticated"></NavbarComponent>
  <div class="flex flex-col min-h-screen bg-gray-50 dark:bg-gray-900" :class="isAuthenticated?'pt-16':''">
    <SidebarComponent v-if="isAuthenticated"></SidebarComponent>
    <div v-if="isAuthenticated" class="fixed inset-0 z-10 hidden bg-gray-900/50 dark:bg-gray-900/90" id="sidebarBackdrop"></div>
    <div id="main-content"  class="relative w-full h-full overflow-y-auto bg-gray-50  dark:bg-gray-900">
      <main>
        <div class="px-4 pt-6" :class="isAuthenticated ? (isSidebarCompact ? 'ml-16' : 'ml-64') : ''">
        <router-view></router-view>
        </div>
      </main>

    </div>

  </div>
  <FooterComponent></FooterComponent>
  <ToastComponent />
</template>
<script>

import SidebarComponent from "@/components/layout/Sidebar.vue";
import NavbarComponent from "@/components/layout/Navbar.vue";
import FooterComponent from "@/components/layout/Footer.vue";
import {mapGetters} from "vuex";
import ToastComponent from "@/components/toast/ToastComponent.vue";

export default {
  name: 'App',
  components: {
    ToastComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(['isAuthenticated','isSidebarOpen', 'isSidebarCompact']),
    // Check if the user is logged in
  },
  watch: {
    // Watch the route to refresh authentication status
    $route(to, from) {
      console.log(from,to)
      this.checkAuthStatus();
    }
  },
  created() {
    // Refresh auth state when the app is created
    this.checkAuthStatus();
  },
  methods: {
    // Method to check authentication status
    checkAuthStatus() {
      // Call Vuex action to set authentication state based on localStorage
      if (localStorage.getItem('auth')) {
        this.$store.commit('login'); // Or use action if you prefer
      } else {
        this.$store.commit('logout');
      }
    }
  }
}
</script>

